import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { BsPersonCircle } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { createAccount } from "../Redux/authSlice";
import { IoIosSend } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadcrumbLinks = [
    // { label: "Home", url: "/" },
    { label: "Already have account? Login", url: "/login" },
  ];
  const pageTitle = "Create Account";

  const [previewImage, setImagePreview] = useState("");

  // for user input
  const [signupData, setSignupData] = useState({
    fullName: "",
    email: "",
    password: "",
    avatar: "",
  });

  // function to set the signup data
  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setSignupData({
      ...signupData,
      [name]: value,
    });
  };

  // function to handle the image upload
  const getImage = (event) => {
    event.preventDefault();
    // getting the image
    const uploadedImage = event.target.files[0];

    // if image exists then getting the url link of it
    if (uploadedImage) {
      setSignupData({
        ...signupData,
        avatar: uploadedImage,
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(uploadedImage);
      fileReader.addEventListener("load", function () {
        // console.log(this.result);
        setImagePreview(this.result);
      });
    }
  };

  // function to create account
  const createNewAccount = async (event) => {
    event.preventDefault();

    // checking the empty fields
    if (
      // !signupData.avatar ||
      !signupData.email ||
      !signupData.fullName ||
      !signupData.password
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    // checking the name field length
    if (signupData.fullName.length < 4) {
      toast.error("Name should be atleast of 5 characters");
      return;
    }

    // email validation using regex
    if (
      !signupData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      toast.error("Invalid email id");
      return;
    }

    // password validation using regex
    if (!signupData.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/)) {
      toast.error(
        "Minimum password length should be 8 with Uppercase, Lowercase, Number and Symbol"
      );
      return;
    }

    // creating the form data from the existing data
    const formData = new FormData();
    formData.append("fullName", signupData.fullName);
    formData.append("email", signupData.email);
    formData.append("password", signupData.password);
    formData.append("avatar", signupData.avatar);

    // calling create account action
    const res = await dispatch(createAccount(formData));

    // redirect to login page if true
    if (res?.payload?.success) navigate("/login");

    // console.log("res.payload.success", res.payload.success);

    // clearing the signup inputs
    setSignupData({
      fullName: "",
      email: "",
      password: "",
      avatar: "",
    });
    setImagePreview("");
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Signup Form */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form noValidate onSubmit={createNewAccount}>
                <div className="register_form signup_login_form">
                  <div className="form_item text-center">
                    {/* input for image file */}
                    <label className="cursor-pointer" htmlFor="image_uploads">
                      {previewImage ? (
                        <img
                          className="w-24 h-24 rounded-full m-auto"
                          src={previewImage}
                          alt="preview img"
                        />
                      ) : (
                        <BsPersonCircle className="w-24 h-24 rounded-full m-auto" />
                      )}
                      <span className="font-semibold">Upload Image</span>
                    </label>
                    <div className="hidden">
                      <input
                        onChange={getImage}
                        className="hidden"
                        type="file"
                        id="image_uploads"
                        name="image_uploads"
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>
                  </div>
                  <div className="form_item">
                    <label className="font-semibold" htmlFor="fullName">
                      Name
                    </label>
                    <input
                      required
                      type="name"
                      name="fullName"
                      id="fullName"
                      placeholder="Enter your name"
                      value={signupData.fullName}
                      onChange={handleUserInput}
                    />
                  </div>
                  <div className="form_item">
                    <label className="font-semibold" htmlFor="email">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      value={signupData.email}
                      onChange={handleUserInput}
                    />
                  </div>
                  <div className="form_item">
                    <label className="font-semibold" htmlFor="password">
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      value={signupData.password}
                      onChange={handleUserInput}
                    />
                  </div>
                  <button type="submit" className="btn btn_primary mb-5">
                    <div className="flex justify-center items-center">
                      <span>
                        <small>Signup Now</small>
                        <small>Signup Now</small>
                      </span>
                      <IoIosSend className="ml-1 text-xl mb-1" />
                    </div>
                  </button>
                  <div>
                    <p className="mb-0 text-center">
                      Already have an account ?{" "}
                      <Link to={"/login"}>
                        <span className="text-blue-600">Login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
