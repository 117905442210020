import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { BiSolidPurchaseTag } from "react-icons/bi";
import axiosInstance from "../../Helper/axiosInstance";
import { purchaseCourseBundle } from "../../Redux/razorpaySlice";
import { MdOutlineDone } from "react-icons/md";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"
import { FaArrowAltCircleRight, FaStar } from "react-icons/fa";

const CourseDescription = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log("state", state);
  const navigate = useNavigate();
  const { role, data } = useSelector((state) => state.auth);
  const { coursesData } = useSelector((state) => state.course);
  console.log(coursesData);
  const [subscriptionCourse, setSubscriptionCourse] = useState([]);

  const pageTitle = "Course Details";
  const breadcrumbLinks = [
    { label: "Home", url: "/" },
    { label: "Our Courses", url: "/course" }
  ];
  useEffect(() => {
    axiosInstance
      .get("/user/subscription")
      .then((value) => setSubscriptionCourse(value.data.data))
      .catch((err) => console.log(err.message));
  }, []);
  const currentCourse = subscriptionCourse.filter((subs) => {
    return subs.courseId._id === state._id && subs.status === "active";
  });

  const handleCheckout = async () => {
    console.log("data", data);

    if (role === "ADMIN" || currentCourse[0]?.status === "active") {
      navigate(`/course/displaylectures/${state._id}`, { state: { ...state } });
    } else {
      console.log(state.planId);
      await dispatch(
        purchaseCourseBundle({ courseId: state?._id, planId: state?.planId })
      );
      navigate("/checkout", {
        state: {
          courseId: state?._id,
          planId: state?.planId,
          courseFee: state?.courseFee,
        },
      });
    }
  };

  useEffect(() => {
    // scroll to the top on page render
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />


      {/* course discription */}
      <section className="details_section course_details_section mb-10">
        <div>
          <div className="lg:px-5 sm:px-1 md:px-1 xl:px-1">
            <div className="row justify-around">
              <div className="col col-lg-7 scale-90">
                <h1
                  className="details_item_title"
                >
                  {state.title}
                </h1>
                <div className="flex items-center flex-wrap ">
                  <div className="course_details_video">
                    <img
                      className="w-auto h-64 rounded-md"
                      src={state?.thumbnail?.secure_url}
                      alt="thumbnail"
                    />
                  </div>
                  <div className="lg:ml-7">
                    <ul
                      className="course_meta_info unordered_list grid grid-cols-2 lg:grid-cols-4 my-5"
                    >
                      <li>
                        <div className="post_admin d-flex align-items-center">
                          <div className="meta_info">
                            <span className="d-block text-lg">Created By</span>
                            <p className="d-block text-md">{state.createdBy}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <span className="d-block text-lg">Instructor</span>
                        <p className="d-block text-md">{state?.instructor?.name}</p>
                      </li>
                      <li>
                        <span className="d-block text-lg">Total Lectures</span>
                        <p className="d-block text-center text-md">
                          {state.numberOfLectures} Lectures
                        </p>
                      </li>
                      <li>
                        <span className="d-block text-lg">Duration</span>
                        <p className="d-block text-md">
                          {state.duration}
                        </p>
                      </li>
                    </ul>
                    <div
                      className="mt-10"
                    >
                      <button
                        onClick={handleCheckout}
                        class="btn btn_primary flex gap-2 items-center text-white"
                      >
                        {role === "ADMIN" ||
                        currentCourse[0]?.status === "active" ? (
                          <span>
                            <small>Watch Lectures</small>
                            <small>Watch Lectures</small>
                          </span>
                        ) : (
                          <span>
                            <small>Subscribe to course</small>
                            <small>Subscribe to course</small>
                          </span>
                        )}
                        <BiSolidPurchaseTag className="text-xl" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="content_wrap">
                  <COMPONENT_ROUTE.CourseTabs pdfLink={state.roadmapPdf} description={state.description} project={state.projects} courseTech={state.courseTechnology} />
                  {/* <p
                    className="my-3"
                  >
                    {state.description}
                  </p> */}
                </div>
                {/* <CourseTabs description={state.description} /> */}
              </div>

              <div className="col col-lg-4">
                {/* //============instructor details section================== */}
                <h1 className="text-center font-semibold text-3xl scale-90 px-2 py-3 bg-[#111f6b] rounded-lg text-white">
                  Instructor Details
                </h1>
                <div className="scale-90" aria-labelledby="profile-overview-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">
                    <div className="bg-white p-6">
                      <div className="">
                        <div className="sm:flex sm:space-x-5 flex-col flex">
                          <div className="flex-shrink-0">
                            <img
                              className="mx-auto h-20 w-20 rounded-full"
                              src="https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1717804800&semt=ais_user"
                              alt=""
                            />
                          </div>
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                           {state?.instructor?.name}

                            </p>
                            <p className="font-medium text-lg text-gray-600">
                              {state?.instructor?.experties}
                            </p>
                          </div>
                        </div>
                        {/* <div className="mt-2 flex justify-center sm:mt-0">
                          <a
                            href="#"
                            className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            View profile
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="border-t border-gray-200 bg-gray-50 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                      <div className="px-6 py-5 text-sm font-medium text-center">
                        <div className="text-[#111f6b] text-lg font-bold">
                          More than &nbsp;
                          {state?.instructor?.experience} of Experience
                        </div>
                        <div className="text-[#111f6b] flex justify-content-center items-center text-lg">
                          <span className=" font-bold">Rating : &nbsp; </span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        </div>
                      </div>
                      {/* <div className="px-6 py-5 text-sm font-medium text-center">
                        <span className="text-gray-600">{state?.instructor?.email}</span>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* =============course Features card============== */}
                <h1 className="text-center font-semibold text-3xl scale-90 px-2 py-3 bg-[#111f6b] rounded-lg text-white">
                  Course Features
                </h1>
                <div  className="scale-90" aria-labelledby="profile-overview-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">
                    <div className="bg-white">
                      <div className="justify-around sm:flex flex-col sm:items-center lg:items-stretch sm:justify-between">
                        <div className="flex flex-col items-center justify-center sm:flex sm:space-x-5">
                          <div className="text-8xl  sm:mt-0">
                            <img
                              className="h-[100px] w-auto"
                              src="https://img.freepik.com/free-vector/award-ribbon-check-mark-with-stars-gradient-style_78370-1098.jpg?w=740&t=st=1718025481~exp=1718026081~hmac=f2c68a5d37f498f7979a072cb740ef665a4c3e0d40f8252e8b50b358bdf12a74"
                              alt="log"
                            />
                          </div>
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                              Course Perks
                            </p>
                            <p className="text-sm font-medium text-gray-600">
                              MERN Full Stack
                            </p>
                          </div>
                        </div>
                        <div className="my-3 px-3">
                          <table className="w-full">
                            <tr className="my-5">
                              <td className="text-[#111f6b] font-larger font-extrabold text-xl"><FaArrowAltCircleRight /></td>
                              <td className="text-start text-[#111f6b] font-larger font-extrabold text-xl">Certifiation</td>
                              <td className="text-[#111f6b]">
                                <div className="h-6 w-6 bg-green-600 rounded-full text-md flex item-center justify-center text-white font-semibold">
                                  <i className="m-auto">
                                    <MdOutlineDone />
                                  </i>
                                </div>
                              </td>
                            </tr>
                            <tr className="my-5">
                            <td className="text-[#111f6b] font-larger font-extrabold text-xl"><FaArrowAltCircleRight /></td>
                              <td className="text-start text-[#111f6b] font-larger font-extrabold text-xl">Expert Trainer</td>
                              <td className="text-[#111f6b]">
                                <div className="h-6 w-6 bg-green-600 rounded-full text-md flex item-center justify-center text-white font-semibold">
                                  <i className="m-auto">
                                    <MdOutlineDone />
                                  </i>
                                </div>
                              </td>
                            </tr>
                            <tr className="my-5">
                            <td className="text-[#111f6b] font-larger font-extrabold text-xl"><FaArrowAltCircleRight /></td>
                              <td className="text-start text-[#111f6b] font-larger font-extrabold text-xl">Internship Certification</td>
                              <td className="text-[#111f6b]">
                                <div className="h-6 w-6 bg-green-600 rounded-full text-md flex item-center justify-center text-white font-semibold">
                                  <i className="m-auto">
                                    <MdOutlineDone />
                                  </i>
                                </div>
                              </td>
                            </tr>
                            <tr className="my-5">
                            <td className="text-[#111f6b] font-larger font-extrabold text-xl"><FaArrowAltCircleRight /></td>
                              <td className="text-start text-[#111f6b] font-larger font-extrabold text-xl">Weekly Doubt Sessions</td>
                              <td className="text-[#111f6b]">
                                <div className="h-6 w-6 bg-green-600 rounded-full text-md flex item-center justify-center text-white font-semibold">
                                  <i className="m-auto">
                                    <MdOutlineDone />
                                  </i>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CourseDescription;
