import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { login } from "../Redux/authSlice";
import { IoIosSend } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadcrumbLinks = [
    // { label: "Home", url: "/" },
    { label: "Please enter your Email & Password", url: "/login" },
  ];
  const pageTitle = "Account Login";

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // function to handle the user input
  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // function to login
  const handleLogin = async (event) => {
    event.preventDefault();

    // checking the empty fields
    if (!loginData.email || !loginData.password) {
      toast.error("Please fill all the fields");
      return;
    }

    // calling login action
    const res = await dispatch(login(loginData));

    // redirect to home page if true
    if (res?.payload?.success) navigate("/");

    // clearing the login inputs
    setLoginData({
      email: "",
      password: "",
    });
  };

  return (
    <Layout>
      {/* Breadcrumb */}

      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Login Form */}
      <div className="register_section section_space_lg pt-0 mt-40">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleLogin}>
                <div className="register_form signup_login_form">
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="email">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      value={loginData.email}
                      onChange={handleUserInput}
                    />
                  </div>
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="password">
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      name="password"
                      id="password"
                      placeholder="**********"
                      value={loginData.password}
                      onChange={handleUserInput}
                    />
                  </div>
                  <div className="remenber_forget row flex mb-3 align-items-center justify-between">
                    <div className="col-lg-9 col-md-12 col-sm-12 col-xl-8">
                      <span className="mb-0 lg:text-left xl:text-left md:text-center sm:text-center">
                        Don't have an account?{" "}
                        <Link to={"/signup"}>
                          <span className="text-blue-600">Register Here</span>
                        </Link>
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-xl-4">
                      <div className="mb-0 lg:text-left xl:text-left md:text-center sm:text-center">
                        <Link to={"/forgetpassword"}>
                          <span className="text-blue-600">Forget Password</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Login</small>
                          <small>Login</small>
                        </span>
                        <IoIosSend className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
