import React from "react";
import { baseUrl } from "../Helper/baseUrl";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPhoneVolume,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";

const Footer = () => {
  const newDate = new Date();
  const year = newDate.getFullYear();

  return (
    <>
      {/* adding the footer */}
      <footer className="relative left-0 bottom-0 px-3 h-fit py-5 flex flex-col sm:flex-row justify-around sm:px-20 text-white bg-[#102471]">
        <section className="mb-4">
          <div className="bg-white mx-auto rounded-lg px-5 w-[250px]">
            <img
              src={`${baseUrl()}/assets/images/logo/mindcoderlogo.webp`}
              alt="logo"
            />
          </div>
        </section>
        {/* adding copyright section */}
        <section className="text-lg">
          <div>
            <p>Primary Pagess</p>
            <hr />
            <ul className="mt-2">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/courses">Courses</Link>
              </li>
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
              <li>
                <Link to="https://www.mindcoders.in/jobs.html" target="_blank">
                  Jobs
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="text-lg">
          <div>
            <p>Other Links</p>
            <hr />
            <ul className="mt-2">
              <li>
                <Link
                  to="https://www.mindcoders.in/privacypolicy.html"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.mindcoders.in/terms&condition.html"
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="https://www.mindcoders.in/blog.html" target="_blank">
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <ul className="mt-4 gap-4 flex flex-wrap">
            <li>
              <a
                className="hover:text-gray-400 flex gap-2 items-center"
                href="mailto:contact@mindcoders.in"
              >
                <SiGmail />
                contact@mindcoders.in
              </a>
            </li>{" "}
            |
            <li>
              <a
                className="hover:text-gray-400 flex gap-2 items-center"
                href="tel:7674040233"
              >
                <FaPhoneVolume />
                7674040233
              </a>
            </li>
            |
          </ul>
          <ul className="mt-4 flex justify-evenly">
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/profile.php?id=61552791473961"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare className="text-5xl" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.instagram.com/mindcodersofficial/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagramSquare className="text-5xl" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://twitter.com/Mindcoders1"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter className="text-5xl" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/company/mind-coders/?viewAsMember=true"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin className="text-5xl" />
              </a>
            </li>
          </ul>
        </section>
      </footer>
      <p className="bg-[#050f38] text-white text-center py-2">
        @copyright {year} | All Rights Reserved by Mindcoders
      </p>
    </>
  );
};

export default Footer;
