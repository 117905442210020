import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
} from "@material-tailwind/react";
import { FaFacebook } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";

const CourseTabs = ({ description, courseTech ,project,pdfLink}) => {
  console.log(courseTech)
  console.log(project)
  const data = [
    {
      label: "Description",
      value: "description",
      title: "OverView",
      roadmap:  courseTech ,
      icon: "dfda",
      project:"",
      desc: `${description}`,
    },
    {
      label: "Content",
      value: "content",
      title: "Course Content",
      roadmap:  courseTech ,
      icon: "UserCircleIcon",
      project:"",
      desc: `Before You Start Here Is everything that you need to know about the Course . below are the topic and concepts which are covered under this course.`,
    },
    {
      label: "Projects",
      value: "projects",
      icon: "Cog6ToothIcon",
      project:"",
      roadmap:  courseTech ,
      title: "Course Roadmap",
      desc: `Project list will upload soon... please check after few days - Mind Coders Team `,
    },
  ];
  return (
    <Tabs value="description">
      <TabsHeader style={{ height: "3rem", marginTop: "2rem" }}>
        {data.map(({ label, value, icon }) => (
          <Tab defaultValue="description" key={value} value={value}>
            <div className="flex items-center font-bold gap-2">{label}</div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc, title,roadmap }) => (
          <TabPanel key={value} value={value}>
            <h1 className="text-3xl font-bold capitalize">{title}</h1>
            <p className="my-4 text-justify"> {desc}</p>
            <div>
              {/* //============timeline Area================ */}
              {value === "content" && (
                <Timeline>
                  {roadmap.map((item)=>( <TimelineItem>
                      <TimelineConnector />
                      <TimelineHeader>
                        <TimelineIcon className="p-1 lg:text-3xl sm-xl md:text-3xl">
                         <AiFillCheckCircle />
                        </TimelineIcon>
                        <Typography variant="h5" color="blue-gray">
                        {item.label}
                        </Typography>
                      </TimelineHeader>
                      <TimelineBody className="pb-8">
                      <Typography
                        color="gary"
                        className="font-normal text-gray-600"
                      >
                        {item.value}
                      </Typography>
                    </TimelineBody>
                    </TimelineItem>))}
                </Timeline>
              )}

              {/* //===============timeline Area close================== */}
            </div>
            {value === "content" && (<a href={pdfLink} rel="noreferrer" download target={"_blank"} ><div className="bg-[#3A10E5] rounded-md p-3 hover:bg-blue-400 cursor-pointer my-2 text-white text-lg font-bold w-fit" > Download Syllabus</div></a>)}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};
export default CourseTabs;
