import React from "react";

const ClientScroller = () => {
  return (
    <section className="relative font-inter antialiased">
      <div className="section_heading text-center">
        <h2 className="heading_text mb-0">
          We are powered by
          <span className="heading_focus_text"> industry.</span>
        </h2>
      </div>
      <main className="relative flex flex-col justify-center overflow-hidden bg-slate-100 py-8"  data-aos="fade-left" data-aos-duration="1500">
        <div className="w-full mx-auto px-4 md:px-6">
          <div className="text-center">
            {/* Logo Carousel animation */}
            <div
              x-data="{}"
              x-init="$nextTick(() => {
                      let ul = $refs.logos;
                      ul.insertAdjacentHTML('afterend', ul.outerHTML);
                      ul.nextSibling.setAttribute('aria-hidden', 'true');
                  })"
              className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
            >
              <ul
                x-ref="logos"
                className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
              >
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_5.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_1.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_2.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_3.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_4.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_5.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_1.png"
                    alt="Miranda"
                  />
                </li>
                <li>
                  <img
                    src="assets/images/sponsors/sponsor_logo_3.png"
                    alt="Miranda"
                  />
                </li>
              </ul>
            </div>
            {/* End: Logo Carousel animation */}
          </div>
        </div>
      </main>
    </section>
  );
};

export default ClientScroller;
