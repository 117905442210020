import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";

const Layout = ({ children }) => {


  return (
    <div className="min-h-[90vh]">
      <div className="hidden lg:block">
        <Header />
      </div>

      {/* adding the daisy ui drawer */}
      <div className="block lg:hidden">
        <SideBar/>
      </div>

      {children}

      {/* adding the footer content */}
      <Footer />
    </div>
  );
};

export default Layout;
