import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import CourseCard from "../Components/CourseCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses } from "../Redux/courseSlice";
import { baseUrl } from "../Helper/baseUrl";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const Homepage = () => {
  const dispatch = useDispatch();
  const { coursesData } = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  return (
    <Layout>
      {/* Main banner section */}
      <COMPONENT_ROUTE.MainBanner />

      {/* Countup section */}
      <div>
        <COMPONENT_ROUTE.CountupCard />
      </div>

      {/*  course cards container */}
      <div className="sm:order-1 lg:order-2">
        <div className="min-h-[90vh] pt-12 flex flex-col flex-wrap gap-10 text-white ">
          <h1 className="text-center text-3xl sm:w-fit md:w-fit lg:w-full font-semibold">
            Explore the courses made by{" "}
            <span className="font-bold text-[#334155]">Industry Experts</span>
          </h1>

          {/* wrapper for courses card */}
          <div className="mb-10 w-[100vw] grid justify-items-center justify-center lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2">
            {coursesData?.map((element) => {
              return <CourseCard key={element._id} data={element} />;
            })}
          </div>
        </div>
      </div>

      {/* Sponser Section */}

      <COMPONENT_ROUTE.ClientScroller/>

      {/* Courses category Section */}
      <section className="category_section section_space_md pb-0">
        <div className="container">
          <div className="section_heading">
            <div className="row align-items-center">
              <div className="col col-lg-6">
                <h2 className="heading_text mb-0">
                  Recent <span className="heading_focus_text">Blogs</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-sm-12" data-aos="zoom-in-right" data-aos-duration="1500">
              <div
                className="category_item"
                style={{
                  backgroundImage:
                    'url("assets/images/shapes/dot_shape_3.png")',
                }}
              >
                <Link
                  data-cursor="-opaque"
                  data-magnetic=""
                  to="https://www.mindcoders.in/what-is-mern.html"
                  target="_blank"
                >
                  <span className="item_icon">
                    <img
                      src={`${baseUrl()}/assets/images/blogs/mern-stack-icon-1.png`}
                      alt="Icon Test Tube"
                    />
                  </span>
                  <strong className="item_title">MERN Stack</strong>
                  <small className="item_counter">
                    10 Essential Tips for Mastering MERN Stack Development and
                    Boosting Your Productivity
                  </small>
                </Link>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12" data-aos="zoom-in-up" data-aos-duration="1500">
              <div
                className="category_item"
                style={{
                  backgroundImage:
                    'url("assets/images/shapes/dot_shape_3.png")',
                }}
              >
                <Link
                  data-cursor="-opaque"
                  data-magnetic=""
                  to="https://www.mindcoders.in/data-analytics-project-for-beginners.html"
                  style={{ transform: "translate3d(0px, 0px, 0px)" }}
                  target="_blank"
                >
                  <span className="item_icon">
                    <img
                      src={`${baseUrl()}/assets/images/blogs/data-analytics-removebg-preview.png`}
                      alt="Icon Calender"
                    />
                  </span>
                  <strong className="item_title">Data Analytics</strong>
                  <small className="item_counter">
                    10 Data Analytics Projects for Beginners to Build Their
                    Skills and Portfolio
                  </small>
                </Link>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12" data-aos="zoom-in-left" data-aos-duration="1500">
              <div
                className="category_item"
                style={{
                  backgroundImage:
                    'url("assets/images/shapes/dot_shape_3.png")',
                }}
              >
                <Link
                  data-cursor="-opaque"
                  data-magnetic=""
                  to="https://www.mindcoders.in/anticipated-trends-shaping-social-media-marketing.html"
                  target="_blank"
                >
                  <span className="item_icon">
                    <img
                      src={`${baseUrl()}/assets/images/blogs/social-media (2).png`}
                      alt="Icon Design"
                    />
                  </span>
                  <strong className="item_title">Digital Marketing</strong>
                  <small className="item_counter">
                    The Top 9 Anticipated Trends Shaping Social Media Marketing
                  </small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Homepage;
