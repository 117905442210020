import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addNewInstructor,
  updateInstructor,
} from "../../Redux/instructorSlice";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes";
import toast from "react-hot-toast";

const AddInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for getting the data from location of previous component
  const locationState = useLocation().state || {};
  console.log(locationState)
  const initialInstructorData = locationState.initialInstructorData || {};
  const isNewInstructor = initialInstructorData.newInstructor;

  console.log("isNewInstructor", isNewInstructor);

  const [instructorData, setInstructorData] = useState({
    name: initialInstructorData?.name,
    email: initialInstructorData?.email,
    password: initialInstructorData?.password,
    experience: initialInstructorData?.experience,
    experties: initialInstructorData?.experties,
  });

  // function to handle user input
  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setInstructorData({
      ...instructorData,
      [name]: value,
    });
  };

  // function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("instructorData", instructorData);

    let res = undefined;

    // for adding a new Instructor
    if (initialInstructorData.newInstructor) {
      //   checking for the empty fields
    
      if (
        !instructorData.name ||
        !instructorData.email ||
        !instructorData.password ||
        !instructorData.experience ||
        !instructorData.experties
      ) {
        toast.error("All fields are mandatory");
        console.log("filed are mendatory");
        return;
      }

      // calling the api
      res = await dispatch(addNewInstructor(instructorData));
    }
    // for updating an existing course
    else {
      //   checking for the empty fields
      if (
        !instructorData.name ||
        !instructorData.email ||
        !instructorData.password ||
        !instructorData.experience ||
        !instructorData.experties
      ) {
        toast.error("All fields are mandatory");
        return;
      }

      const data = { ...instructorData, id: initialInstructorData._id };
      // calling the api
      res = await dispatch(updateInstructor(instructorData));
    }

    // clearing the input fields
    if (res?.payload?.success) {
      setInstructorData({
        name: "",
        email: "",
        password: "",
        experience: "",
        experties: "",
      });

      // redirecting the user to admin dashboard
      navigate("/admin/dashboard");
    }
  };

  // for breadcrumb
  const breadcrumbLinks = [
    { label: "Dashboard", url: "/admin/dashboard" },
    { label: "Update Courses" },
  ];
  const pageTitle = "Add new Instructor";

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb
        pageTitle={pageTitle}
        breadcrumbLinks={breadcrumbLinks}
      />

      <div className="register_section section_space_lg pt-0 mt-[150px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-12">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  {/* for course basic details */}
                  <div className="grid grid-cols-2 gap-4">
                    {/* adding the title section */}
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Instructor Name
                      </label>
                      <input
                        required
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter the Instructor Name"
                        value={instructorData?.name}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Instructor Email
                      </label>
                      <input
                        required
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter the Instructor Email"
                        value={instructorData?.email}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Experience
                      </label>
                      <input
                        required
                        type="text"
                        name="experience"
                        id="experience"
                        placeholder="Enter the experience"
                        value={instructorData?.experience}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Experties
                      </label>
                      <input
                        required
                        type="text"
                        name="experties"
                        id="experties"
                        placeholder="Enter the Experties"
                        value={instructorData?.experties}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Password
                      </label>
                      <input
                        required
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter the password"
                        value={instructorData?.password}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-3 w-[250px] ml-[40%]">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Create Course</small>
                          <small>Create Course</small>
                        </span>
                        <FaPlus className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>

                  <div className="remenber_forget mt-3 text-center justify-between">
                    <div>
                      <div className="forget_password text-center">
                        <Link to={"/admin/dashboard"}>
                          <span className="text-blue-600 flex gap-1 items-center">
                            <IoArrowBackSharp className="text-xl" />
                            Back to Dashboard
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddInstructor;
