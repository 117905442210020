import React, { useEffect, useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Skills from "./skills.json";

const ReactSelect = ({getValue}) => {
  const [options, setOptions] = useState(Skills);
  const [selectOptions, setSelectOptions] = useState([]);
  console.log("selectOpt",selectOptions)
  console.log(getValue(selectOptions))

  const handleChange = (selected) => {
    setSelectOptions(selected);
    getValue(selected);
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "54px", 
      height: "54px", 
      padding: "0px", 
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "54px", 
      padding: "0 15px", 
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px", // Remove margin for better alignment
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "54px", // Ensure the indicators container also respects the height
    }),
  };

  return (
    <Select
      value={selectOptions}
      isMulti
      options={options}
      onChange={handleChange}
      components={makeAnimated()}
      styles={customStyles}
    />
  );
};

export default ReactSelect;
