import React from "react";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { baseUrl } from "../Helper/baseUrl";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const MainBanner = () => {
  return (
    <section className="banner_section banner_style_1 decoration_wrap">
      <div className="container">
        <div className="row align-items-center justify-content-evenly">
          <div className=" col col-lg-6 col-md-8 col-sm-12" data-aos="fade-right" data-aos-duration="1000">
            <div className="banner_image_1">
            <img
            className="main-img"
                  src={`${baseUrl()}/assets/images/banner/main.png`}
                  alt="Banner Img"
                />
            </div>
          </div>
          <div className="col col-lg-5 col-mg-4 col-sm-12">
            <div className="">
              <h1 className="lg:text-[38px] md:text-[50px] sm:text-[450px] w-full font-semibold text-[#3D3D3D]" data-aos="fade-left" data-aos-duration="1000">
                Stay Ahead Of The Curve
                <span className="flex">
                  With Our{" "}
                  <span className="flex items-center text-[#111f6b]">
                    <FaLessThan />
                    <COMPONENT_ROUTE.Typed />
                    <FaGreaterThan />
                  </span>
                </span>
                Courses
              </h1>
              <p className="lg:text-[20px] sm:text-[15px]" data-aos="fade-left" data-aos-duration="1300">
                Mind Coders is your One Stop for all IT Training Needs! We
                provide the best training with industry expert trainers.
              </p>
              {/* <div className=""> */}
              <Link
               data-aos="fade-left" data-aos-duration="1600"
                to="/courses"
                className="opacity-100 lg:w-[40%] text-white text-lg px-3 py-[15px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] hover:scale-105 bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
              >
                <button>Explore Courses</button>
              </Link>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
