import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { createNewCourse, updateCourse } from "../../Redux/courseSlice";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes";

const CreateCourse = () => {
  const [courseTech, setCourseTech] = useState([]);
  console.log("texh", courseTech);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instructorData } = useSelector((state) => state.instructor);
  console.log(instructorData);

  // for getting the data from location of previous component
  const locationState = useLocation().state || {};
  const initialCourseData = locationState.initialCourseData || {};
  const isNewCourse = !initialCourseData.newCourse;

  // for toggling disable of image input box
  const [isDisabled, setIsDisabled] = useState(!initialCourseData?.newCourse);

  // for storing the user input
  const [userInput, setUserInput] = useState({
    title: initialCourseData?.title,
    category: initialCourseData?.category,
    instructor: initialCourseData?.instructor,
    duration: initialCourseData?.duration,
    description: initialCourseData?.description,
    thumbnail: "",
    previewImage: initialCourseData?.thumbnail?.secure_url,
    planId: initialCourseData?.planId,
    courseFee: initialCourseData?.courseFee,
    roadmapPdf: initialCourseData?.roadmapPdf,
    roadmap: initialCourseData?.roadmap,
    project: initialCourseData?.project,
    courseTechnology: [],
  });

  // function to handle the image upload
  const getImage = (event) => {
    event.preventDefault();
    // getting the image
    const uploadedImage = event.target.files[0];
    console.log(uploadedImage);

    // if image exists then getting the url link of it
    if (uploadedImage) {
      // setUserInput({ ...userInput, thumbnail: uploadedImage });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(uploadedImage);
      fileReader.addEventListener("load", function () {
        setUserInput({
          ...userInput,
          previewImage: this.result,
          thumbnail: uploadedImage,
        });
      });
    }
  };

  // function to handle user input
  const handleUserInput = (event) => {
    const { name, value } = event.target;
    setUserInput({
      ...userInput,
      [name]: value,
      courseTechnology: courseTech,
      projects:[{project:"ecommmerce website MERN",projectDiscription:"in this project you will use MERN stack to work on front end , back end and database to create a fully funtional ecommerce website like amazone and flipkart"}]
    });
  };

  // function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let res = undefined;

    // for creating a new course
    if (initialCourseData.newCourse) {
      //   checking for the empty fields
      console.log("userInput", userInput);
      if (
        !userInput.title ||
        !userInput.category ||
        !userInput.instructor ||
        !userInput.description ||
        !userInput.duration ||
        !userInput.thumbnail ||
        !userInput.planId ||
        !userInput.courseFee
      ) {
        toast.error("All fields are mandatory");
        console.log("filed are mendatory");
        return;
      }
      console.log(userInput);
      // calling the api
      res = await dispatch(createNewCourse(userInput));
    }
    // for updating an existing course
    else {
      //   checking for the empty fields
      if (
        !userInput.title ||
        !userInput.category ||
        !userInput.instructor ||
        !userInput.duration ||
        !userInput.description ||
        !userInput.planId ||
        !userInput.courseFee
      ) {
        toast.error("All fields are mandatory");
        return;
      }

      const data = { ...userInput, id: initialCourseData._id };
      // calling the api
      res = await dispatch(updateCourse(data));
    }

    // clearing the input fields
    if (res?.payload?.success) {
      setUserInput({
        title: "",
        category: "",
        instructor: "",
        duration: "",
        description: "",
        thumbnail: undefined,
        previewImage: "",
        planId: "",
        courseFee: null,
        roadmapPdf: "",
        roadmap: "",
        // project: "",
      });

      setIsDisabled(false);

      // redirecting the user to admin dashboard
      navigate("/admin/dashboard");
    }
  };

  // for breadcrumb
  const breadcrumbLinks = [
    { label: "Dashboard", url: "/admin/dashboard" },
    isNewCourse ? { label: "Update Courses" } : { label: "Create Courses" },
    // { label: "Create Courses", url: "/course/create" },
  ];
  const pageTitle = isNewCourse ? "Update Course" : "Create new Course";

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb
        pageTitle={pageTitle}
        breadcrumbLinks={breadcrumbLinks}
      />

      <div className="register_section section_space_lg pt-0 mt-[150px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-12">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  {/* for course basic details */}
                  <div className="grid grid-cols-2 gap-4">
                    <div
                      onClick={() =>
                        !initialCourseData.newCourse
                          ? toast.error("Cannot update thumbnail image")
                          : ""
                      }
                    >
                      {/* input for image file */}
                      <label
                        className="cursor-pointer w-full"
                        htmlFor="image_uploads"
                      >
                        {userInput.previewImage ? (
                          <img
                            className="w-full h-44 m-auto border"
                            src={userInput?.previewImage}
                            alt="preview img"
                          />
                        ) : (
                          <div className="w-full h-44 m-auto flex items-center justify-center border">
                            <h1 className="text-lg font-semibold">
                              Upload your course thumbnail
                            </h1>
                          </div>
                        )}
                      </label>
                      <input
                        onChange={getImage}
                        className="hidden"
                        type="file"
                        id="image_uploads"
                        name="image_uploads"
                        accept=".jpg, .jpeg, .png"
                        disabled={isDisabled}
                      />
                    </div>

                    {/* adding the title section */}
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="description"
                      >
                        Course Description
                      </label>
                      <textarea
                        className="h-[150px]"
                        required
                        type="text"
                        name="description"
                        id="description"
                        placeholder="Enter the course description"
                        value={userInput?.description}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Course Title
                      </label>
                      <input
                        required
                        type="name"
                        name="title"
                        id="title"
                        placeholder="Enter the course title"
                        value={userInput?.title}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="instructor"
                      >
                        Instructor Name
                      </label>
                      <select
                        name="instructor"
                        required
                        id="instructor"
                        value={userInput?.instructor} // Ensure you set the selected value
                        onChange={handleUserInput}
                      >
                        <option>-select-</option>
                        {instructorData?.map((instructor) => (
                          <option key={instructor._id} value={instructor?._id}>
                            {instructor?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="category"
                      >
                        Course Category
                      </label>
                      <input
                        required
                        type="name"
                        name="category"
                        id="category"
                        placeholder="Enter the category name"
                        value={userInput?.category}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div>
                      <label
                        className="text-lg font-semibold"
                        htmlFor="courseTech"
                      >
                        Course Technology
                      </label>
                      <COMPONENT_ROUTE.ReactSelect
                        getValue={(data) => {
                          setCourseTech(data);
                        }}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="roadmapPdf"
                      >
                        Roadmap PDF Link
                      </label>
                      <input
                        required
                        type="text"
                        name="roadmapPdf"
                        id="roadmapPdf"
                        placeholder="Enter the pdf link"
                        value={userInput?.roadmapPdf}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="roadmap"
                      >
                        Roadmap Description
                      </label>
                      <input
                        type="text"
                        name="roadmap"
                        id="roadmap"
                        placeholder="Enter the roadmap description"
                        value={userInput?.roadmap}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="project"
                      >
                        Projects
                      </label>
                      <input
                        type="text"
                        name="project"
                        id="project"
                        placeholder="Enter the project"
                        // value={userInput?.project}
                        // onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="duration"
                      >
                        Course Duration
                      </label>
                      <input
                        required
                        type="text"
                        name="duration"
                        id="duration"
                        placeholder="Enter the duration"
                        value={userInput?.duration}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label
                        className="text-lg font-semibold"
                        htmlFor="courseFee"
                      >
                        Course Fee
                      </label>
                      <input
                        required
                        type="text"
                        name="courseFee"
                        id="courseFee"
                        placeholder="Enter Course Fee"
                        value={userInput?.courseFee}
                        onChange={handleUserInput}
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="planId">
                        Course Plan Id (Razorpay Plan Id)
                      </label>
                      <input
                        required
                        type="text"
                        name="planId"
                        id="planId"
                        placeholder="Enter the Plan Id of Course"
                        value={userInput?.planId}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-3 w-[250px] ml-[40%]">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Create Course</small>
                          <small>Create Course</small>
                        </span>
                        <FaPlus className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>

                  <div className="remenber_forget mt-3 text-center justify-between">
                    <div>
                      <div className="forget_password text-center">
                        <Link to={"/admin/dashboard"}>
                          <span className="text-blue-600 flex gap-1 items-center">
                            <IoArrowBackSharp className="text-xl" />
                            Back to Dashboard
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateCourse;
