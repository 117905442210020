import React from "react";
import { FiMenu } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/authSlice";
import { baseUrl } from "../Helper/baseUrl";
import { FaAt, FaHome, FaUser } from "react-icons/fa";
import { FaBook, FaBookOpenReader, FaCircleInfo } from "react-icons/fa6";
import { RiAdminFill } from "react-icons/ri";

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // for checking user logged in or not
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  // for dispaying the options, according to user role
  const role = useSelector((state) => state?.auth?.role);

  // function to hide the drawer on close button click
  const hideDrawer = () => {
    const element = document.getElementsByClassName("drawer-toggle");
    element[0].checked = false;

    // collapsing the drawer-side width to zero
    const drawerSide = document.getElementsByClassName("drawer-side");
    drawerSide[0].style.width = 0;
  };

  // function for changing the drawer width on menu button click
  const changeWidth = () => {
    const drawerSide = document.getElementsByClassName("drawer-side");
    drawerSide[0].style.width = "100vw";
  };

  // function to handle logout
  const handleLogout = async (event) => {
    event.preventDefault();

    // calling logout action
    const res = await dispatch(logout());

    // redirect to home page if true
    if (res?.payload?.success) navigate("/");
  };

  return (
    <div className=" fixed z-50 left-0 w-full h-0">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="z-3 w-full fixed">
        <label
          htmlFor="my-drawer"
          className="cursor-pointer flex justify-between items-center bg-white w-full relative"
        >
          <div className="site_logo">
            <Link className="site_link" to={"/"}>
              <img
                className="h-[60px]"
                src={`${baseUrl()}/assets/images/logo/mindcoderlogo.webp`}
                alt="Education, Online Course, LMS Creative"
              />
            </Link>
          </div>
          <FiMenu
            onClick={changeWidth}
            size={"32px"}
            className="font-bold text-[#090E23] m-4"
          />
        </label>
      </div>

      <div className="drawer-side w-0 h-[100vh] duration-150">
        <label htmlFor="my-drawer" className="drawer-overlay"></label>
        <table className="menu flex flex-col gap-2 p-4 w-full sm:w-80 bg-white text-base-content text-xl relative">
          {/* close button for drawer */}
          <tr className="w-fit absolute right-2 z-50">
            <button onClick={hideDrawer} m-auto>
              <AiFillCloseCircle size={24} />
            </button>
          </tr>
          <Link to="/">
            <div
              onClick={hideDrawer}
              className="bg-white mx-auto mb-8 rounded-lg px-5 w-[250px]"
            >
              <img
                src={`${baseUrl()}/assets/images/logo/mindcoderlogo.webp`}
                alt="logo"
              />
            </div>
          </Link>
          <Link to={"/"}>
            {" "}
            <tr
              onClick={hideDrawer}
              className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
            >
              <td>
                <FaHome />
              </td>
              <td>Home</td>
            </tr>
          </Link>

          {/* displaying dashboard, if user is logged in */}
          {isLoggedIn && role === "ADMIN" && (
            <Link
              to={"/admin/dashboard"}
              className={
                location.pathname === "/admin/dashboard" ? "active" : ""
              }
            >
              <tr
                onClick={hideDrawer}
                className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
              >
                <td>
                 <RiAdminFill />
                </td>
                <td>Admin Dashboard</td>
              </tr>
            </Link>
          )}

          {/* displaying myCourses, if user is logged in */}
          {isLoggedIn && role === "USER" && (
            <Link
              to={"/mycourses"}
              className={location.pathname === "/mycourses" ? "active" : ""}
            >
              {" "}
              <tr
                onClick={hideDrawer}
                className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
              >
                <td>
                  <FaBookOpenReader />
                </td>
                <td>My Courses</td>
              </tr>
            </Link>
          )}

          <Link to={"/course"}>
            {" "}
            <tr
              onClick={hideDrawer}
              className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
            >
              <td>
                <FaBook />
              </td>
              <td>All Courses</td>
            </tr>
          </Link>

          <Link to={"/contact"}>
            {" "}
            <tr
              onClick={hideDrawer}
              className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
            >
              <td>
                <FaAt />
              </td>
              <td>Contact Us</td>
            </tr>
          </Link>

          <Link to={"/about"}>
            {" "}
            <tr
              onClick={hideDrawer}
              className="flex items-center gap-2 p-2 bg-gray-300 text-black font-bold rounded-2xl mt-2"
            >
              <td>
                <FaCircleInfo />
              </td>
              <td>About Us</td>
            </tr>
          </Link>

          {/* creating the bottom part of drawer */}
          {/* if user is not logged in */}
          {!isLoggedIn && (
            <tr className="w-full absolute bottom-0 mb-20">
              <div className="flex gap-2 flex-row items-start">
                <Link
                  to={"/login"}
                  className="opacity-100 w-[40%] text-white text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12"
                >
                  {/* <button > */}
                  Login
                  {/* </button> */}
                </Link>
                <Link
                  to={"/signup"}
                  className="opacity-100 w-[40%] text-white text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
                >
                  {/* <button > */}
                  Signup
                  {/* </button> */}
                </Link>
              </div>
            </tr>
          )}

          {/* if user is logged in */}
          {isLoggedIn && (
            <tr className="w-full absolute bottom-0 mb-20">
              <div className="flex gap-2 flex-row items-start">
                <Link
                  to={"/user/profile"}
                  className="opacity-100 w-[40%] text-white text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
                >
                  Profile
                </Link>
                <Link
                  onClick={handleLogout}
                  className="opacity-100 w-[40%] text-white text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
                >
                  Logout
                </Link>
              </div>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

export default SideBar;
