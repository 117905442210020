import React from "react";
import { useNavigate } from "react-router-dom";

const CourseCard = ({ data, courseStatus }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/course/description", { state: { ...data } })}
      className="w-[25rem] scale-[0.85] sm:scale-90 lg:scale-100 md:scale-100 xl:scale-100 text-black shadow-lg rounded-lg cursor-pointer group overflow-hidden bg-white mt-10"
    >
      <div className="overflow-hidden">
        <img
          className="h-48 w-full rounded-tl-lg rounded-tr-lg group-hover:scale-[1.2] transition-all ease-in-out duration-300 "
          src={data?.thumbnail?.secure_url}
          alt="course thumbnail"
        />
      </div>
      <div className="p-3 space-y-1">
        <h2 className="text-xl font-bold line-clamp-2">{data?.title}</h2>
        <p className="line-clamp-2">{data?.description}</p>
        {courseStatus && (
          <div
            id="active-status-btn"
            className={
              courseStatus?.status === "active"
                ? "inline-flex text-[15px] items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                : "inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-red-600 text-[15px]"
            }
          >
            {courseStatus?.status}
          </div>
        )}
        <div className="flex mt-5 justify-content-around">
          {courseStatus?.status !== "active" ? (
            <>
              {" "}
              <button
                onClick={() =>
                  navigate("/course/description", { state: { ...data } })
                }
                className="opacity-100 w-[40%] border border-[#111f6b] text-[#111f6b] hover:text-[#4b599d] hover:border-[#4b599d] text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex  justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-white font-extrabold large:!font-bold"
              >
                <del>
                  {" "}
                  <small className="text-[#4b599d]">
                    <strong>₹</strong>
                    {data?.courseFee + 1000}{" "}
                  </small>
                </del>
                <strong className="ml-2">₹</strong>
                {data?.courseFee}
              </button>
              <button
                onClick={() =>
                  navigate("/course/description", { state: { ...data } })
                }
                className="opacity-100 w-[40%] text-white text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2 justify-center items-center font-nunitoSans cursor-pointer hover:bg-[#4b599d] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
              >
                Buy Now
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() =>
                  navigate("/course/description", { state: { ...data } })
                }
                className="opacity-100 w-[40%] border border-[#111f6b] text-[#111f6b] hover:text-[#4b599d] hover:border-[#4b599d] text-sm px-3 py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex  justify-center items-center font-nunitoSans cursor-pointer hover:bg-[none] bg-white font-extrabold large:!font-bold"
              >
                Explore Course
              </button>
              <button
                onClick={() =>
                  navigate("/course/displaylectures", { state: { ...data } })
                }
                className="opacity-100 w-[40%] text-white text-sm px-3 self-start py-[10px] medium:text-base medium:px-6 medium:py-3 rounded-md flex gap-2  items-center font-nunitoSans cursor-pointer hover:bg-[#4b599d] bg-[#111f6b] font-extrabold large:!font-bold !h-10 medium:!h-12 "
              >
                Watch Lacture
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
