import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsPersonCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { getUserData, updateProfile } from "../../Redux/authSlice";
import { FaRegSave } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const EditProfile = () => {
  const dispatch = useDispatch();
  const [previewImage, setImagePreview] = useState("");

  const pageTitle = "Edit Profile";
  const breadcrumbLinks = [
    { label: "Profile", url: "/user/profile" },
    { label: "Edit Profile", url: "/user/editprofile" },
  ];

  const [data, setData] = useState({
    fullName: "",
    avatar: undefined,
    userID: useSelector((state) => state?.auth?.data?._id),
  });

  // function to handle the image upload
  const getImage = (event) => {
    event.preventDefault();
    // getting the image
    const uploadedImage = event.target.files[0];

    // if image exists then getting the url link of it
    if (uploadedImage) {
      setData({
        ...data,
        avatar: uploadedImage,
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(uploadedImage);
      fileReader.addEventListener("load", function () {
        setImagePreview(this.result);
      });
    }
  };

  // function to set the name of user
  const setName = (event) => {
    const { name, value } = event.target;
    const newUserData = { ...data, [name]: value };
    setData(newUserData);
  };

  // function to handle the form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // checking for the empty field
    if (!data.fullName || !data.avatar) {
      toast.error("All fields are mandatory");
      return;
    }

    // checking the length of name
    if (data.fullName.length < 4) {
      toast.error("Name should have more than 4 characters");
      return;
    }

    // creating the form data from the existing data
    const formData = new FormData();
    formData.append("fullName", data.fullName);
    formData.append("avatar", data.avatar);

    const newUserData = [data.userID, formData];

    // dispatching the api call using the thunk
    await dispatch(updateProfile(newUserData));

    // fetching the data to update
    await dispatch(getUserData());
  };

  return (
    <Layout>
      {/* Breadcrumb section */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Form card */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  <div className="form_item text-center">
                    <label className="cursor-pointer " htmlFor="image_uploads">
                      {previewImage ? (
                        <img
                          className="w-28 h-28 rounded-full m-auto"
                          src={previewImage}
                          alt="preview img"
                        />
                      ) : (
                        <BsPersonCircle className="w-28 h-28 rounded-full m-auto" />
                      )}
                      <span className="font-semibold">Upload Image</span>
                    </label>
                    <div className="hidden">
                      <input
                        onChange={getImage}
                        className="hidden"
                        type="file"
                        id="image_uploads"
                        name="image_uploads"
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>
                  </div>
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="password">
                      Full Name
                    </label>
                    <input
                      required
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder="Enter your full name"
                      value={data.fullName}
                      onChange={setName}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Update Profile</small>
                          <small>Update Profile</small>
                        </span>
                        <FaRegSave className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                  <p className="mt-2 text-center">
                    <Link to={"/user/profile"}>
                      <span className="text-blue-600 flex items-center">
                        <IoMdArrowRoundBack className="text-2xl" />
                        Back to Profile
                      </span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditProfile;
