import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { FaPen, FaUsers } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { FcSalesPerformance } from "react-icons/fc";
import { MdDelete, MdVideoLibrary } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCourse, getAllCourses } from "../../Redux/courseSlice";
import { getStatsData } from "../../Redux/statSlice";
import { getPaymentRecord } from "../../Redux/razorpaySlice";
import { TiPlus } from "react-icons/ti";
import { confirmAlert } from "react-confirm-alert";
import { getUserData } from "../../Redux/authSlice";
import { getCourseLecture } from "../../Redux/lectureSlice";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes";
import { deleteInstructor, getAllInstructor } from "../../Redux/instructorSlice";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for breadcrumb
  const pageTitle = "Admin Dashboard";

  const {instructorData} = useSelector((state)=>state.instructor)

  const { allUsersCount, subscribedUsersCount } = useSelector(
    (state) => state.stat
  );
  const { allPayments, finalMonths, monthlySalesRecord } = useSelector(
    (state) => state.razorpay
  );
  console.log(allPayments);

  const userData = {
    labels: ["Registered User", "Enrolled User"],
    datasets: [
      {
        label: "User Details",
        data: [allUsersCount, subscribedUsersCount],
        backgroundColor: ["#0FCFFF", "green"],
        borderColor: ["#0FCFFF", "green"],
        borderWidth: 1,
      },
    ],
  };

  console.log("userData", userData);

  const salesData = {
    labels: [
      "January",
      "Febraury",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    fontColor: "white",
    datasets: [
      {
        label: "Sales / Month",
        data: monthlySalesRecord,
        backgroundColor: ["rgb(255, 99, 132)"],
        borderColor: ["white"],
        borderWidth: 2,
      },
    ],
  };

  // getting the courses data from redux toolkit store
  const myCourses = useSelector((state) => state.course.coursesData);
  console.log("myCourses", myCourses);

  // function to handle the course delete
  const handleCourseDelete = async (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete the course?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const res = await dispatch(deleteCourse(id));

            // fetching the new updated data for the course
            if (res?.payload?.success) {
              await dispatch(getAllCourses());
            }
          },
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing if user clicks 'No'
        },
      ],
    });
  };
  // function to handle the instructor delete
  const handleInstructorDelete = async (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to remove the Instructor?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const res = await dispatch(deleteInstructor(id));

            // fetching the new updated data for the course
            if (res?.payload?.success) {
              await dispatch(getAllInstructor());
            }
          },
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing if user clicks 'No'
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getAllCourses());
    dispatch(getStatsData());
    dispatch(getPaymentRecord());
    dispatch(getUserData());
    dispatch(getAllInstructor());

  }, []);

  return (
    <Layout>
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} />

      <div className="min-h-[90vh] pt-2 flex flex-col flex-wrap gap-10 text-gray-600">
        {/* creating the records card and chart for sales and user details */}
        <div className="grid  sm:grid-cols-1 md:grid-col-1 lg:grid-cols-2 xl:grid-cols-2 gap-5 m-auto mx-10">
          {/* displaying the users chart and data */}
          <div className="flex flex-col items-center gap-10 p-5 shadow-lg rounded-md">
            {/* for displaying the pie chart */}
            <div className="w-80 h-80">
              <Pie data={userData} />
            </div>

            {/* card for user data */}
            <div className="grid sm:grid-cols-1 md:grid-col-1 lg:grid-cols-2 xl:grid-cols-2 gap-5">
              {/* card for registered users */}
              <div className="flex items-center justify-between py-5 px-4 gap-5 rounded-md shadow-md">
                <div className="flex flex-col items-center">
                  <p className="font-semibold">Registered Users</p>
                  <h3 className="text-4xl font-bold">{allUsersCount}</h3>
                </div>
                <FaUsers className="text-[#0FCFFF] text-5xl" />
              </div>

              {/* card for enrolled users */}
              <div className="flex items-center justify-between py-5 px-4 gap-5 rounded-md shadow-md">
                <div className="flex flex-col items-center">
                  <p className="font-semibold">Subscribed Users</p>
                  <h3 className="text-4xl font-bold">{subscribedUsersCount}</h3>
                </div>
                <FaUsers className="text-green-500 text-5xl" />
              </div>
            </div>
          </div>

          {/* displaying the sales chart and data */}
          <div className="flex flex-col items-center gap-10 p-5 shadow-lg rounded-md">
            {/* for displaying the bar chart */}
            <div className="h-80 relative w-full">
              <Bar className="absolute bottom-0 h-80 w-full" data={salesData} />
            </div>

            {/* card for user data */}
            <div className="grid sm:grid-cols-1 md:grid-col-1 lg:grid-cols-2 xl:grid-cols-2 gap-5">
              {/* card for registered users */}
              <div className="flex items-center justify-between py-5 px-3 gap-5 rounded-md shadow-md">
                <div className="flex flex-col items-center">
                  <p className="font-semibold">Subscriptions Count</p>
                  <h3 className="text-4xl font-bold">{allPayments?.count}</h3>
                </div>
                <FcSalesPerformance className="text-[#0FCFFF] text-5xl" />
              </div>

              {/* card for enrolled users */}
              <div className="flex items-center justify-between py-5 px-4 gap-5 rounded-md shadow-md">
                <div className="flex flex-col items-center">
                  <p className="font-semibold">Total Revenue</p>
                  <h3 className="text-4xl font-bold">
                    {allPayments?.count * 499}
                  </h3>
                </div>
                <GiMoneyStack className="text-green-500 text-5xl" />
              </div>
            </div>
          </div>
        </div>

        {/* CRUD courses section */}
        <div className="w-[95%] self-center flex flex-col items-center justify-center gap-10 mb-10">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-center text-3xl font-semibold">
              Courses Overview
            </h1>

            {/* add course card */}
            <button
              onClick={() => {
                navigate("/course/create", {
                  state: {
                    initialCourseData: {
                      newCourse: true,
                      title: "",
                      category: "",
                      instructor: "",
                      description: "",
                      thumbnail: undefined,
                      previewImage: "",
                      planId: "",
                      courseFee: null,
                    },
                  },
                });
              }}
              type="submit"
              className="btn btn_primary"
            >
              <div className="flex justify-center items-center">
                <span>
                  <small>Create New Course</small>
                  <small>Create New Course</small>
                </span>
                <TiPlus className="ml-1 text-xl mb-1" />
              </div>
            </button>
          </div>
          {/* Table component */}
          <div className="w-full rounded-lg border border-gray-200 shadow-md">
            <table className="w-full overflow-x-scroll border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    S No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Course Title
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Created By
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Course Category
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Course Fee
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Instructor
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Total Lectures
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Course Duration
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900 text-center"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                {myCourses?.map((element, index) => {
                  return (
                    <tr key={element?._id} className="hover:bg-gray-50r">
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4">{element?.title}</td>
                      {/* <td className="px-6 py-4">
                        <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-green-600" />
                          {element?.createdBy}
                        </span>
                      </td> */}
                      <td className="px-6 py-4">{element?.category}</td>
                      <td className="px-6 py-4">{element?.courseFee}</td>
                      <td className="px-6 py-4">
                        <div className="flex gap-2">
                          <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                            {element?.instructor?.name}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-center">
                        {element?.numberOfLectures}
                      </td>
                      <td className="px-6 py-4 text-center">
                        {element?.duration}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex justify-end gap-4">
                          <button
                            onClick={() =>
                              navigate("/course/create", {
                                state: {
                                  initialCourseData: {
                                    newCourse: false,
                                    ...element,
                                  },
                                },
                              })
                            }
                            className="btn btn_warning"
                          >
                            <div className="flex justify-center items-center">
                              <span>
                                <small>
                                  <FaPen className="text-[18px]" />
                                </small>
                                <small>
                                  <FaPen className="text-[18px]" />
                                </small>
                              </span>
                            </div>
                          </button>
                          <button
                            onClick={() => handleCourseDelete(element?._id)}
                            className="btn btn_danger"
                          >
                            <div className="flex justify-center items-center">
                              <span>
                                <small>
                                  <MdDelete className="text-[18px]" />
                                </small>
                                <small>
                                  <MdDelete className="text-[18px]" />
                                </small>
                              </span>
                            </div>
                          </button>
                          <button
                            onClick={async () => {
                              await dispatch(getCourseLecture(element?._id));
                              navigate(`/course/displaylectures/${element?._id}`, {
                                state: { ...element },
                              });
                            }}
                            className="btn btn_primary"
                          >
                            <div className="flex justify-center items-center">
                              <span>
                                <small>
                                  <MdVideoLibrary className="text-[18px]" />
                                </small>
                                <small>
                                  <MdVideoLibrary className="text-[18px]" />
                                </small>
                              </span>
                            </div>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Add Instructor */}
        </div>

        {/* CRUD intructor section */}
        <div className="w-[95%] self-center flex flex-col items-center justify-center gap-10 mb-10">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-center text-3xl font-semibold">Instructors</h1>

            {/* add intructor card */}
            <button
              onClick={() => {
                navigate("/add/instructor", {
                  state: {
                    initialInstructorData: {
                      newInstructor: true,
                      name: "",
                      Password: "",
                      email: "",
                      experties: "",
                      experience: "",
                    },
                  },
                });
              }}
              type="submit"
              className="btn btn_primary"
            >
              <div className="flex justify-center items-center">
                <span>
                  <small>Add New Instructor</small>
                  <small>Add New Instructor</small>
                </span>
                <TiPlus className="ml-1 text-xl mb-1" />
              </div>
            </button>
          </div>
          {/* Table component */}
          <div className="w-full rounded-lg border border-gray-200 shadow-md">
            <table className="w-full overflow-x-scroll border-collapse bg-white text-left text-sm text-gray-500">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    S No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Profile
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Instructor Name
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Created By
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Experties
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900"
                  >
                    Experience
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900 text-center"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                {instructorData?.map((instructor, index) => {
                  return (
                    <tr key={instructor?._id} className="hover:bg-gray-50r">
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4">
                        <div className="flex gap-2 w-12 h-12 rounded-full">
                          <img className="w-full h-full" src={instructor.profile} alt="instructor" />
                        </div>
                      </td>
                      <td className="px-6 py-4">{instructor?.name}</td>
                      {/* <td className="px-6 py-4">
                        <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-green-600" />
                          {element?.createdBy}
                        </span>
                      </td> */}
                      <td className="px-6 py-4">{instructor?.email}</td>
                      <td className="px-6 py-4">{instructor?.experties}</td>
                      <td className="px-6 py-4">{instructor?.experience}</td>
                      <td className="px-6 py-4">
                        <div className="flex justify-end gap-4">
                          {/* <button
                            // onClick={() =>
                            //   navigate("/course/create", {
                            //     state: {
                            //       initialCourseData: {
                            //         newCourse: false,
                            //         ...element,
                            //       },
                            //     },
                            //   })
                            // }
                            className="btn btn_warning"
                          >
                            <div className="flex justify-center items-center">
                              <span>
                                <small>
                                  <FaPen className="text-[18px]" />
                                </small>
                                <small>
                                  <FaPen className="text-[18px]" />
                                </small>
                              </span>
                            </div>
                          </button> */}
                          <button
                            onClick={() => handleInstructorDelete(instructor?._id)}
                            className="btn btn_danger"
                          >
                            <div className="flex justify-center items-center">
                              <span>
                                <small>
                                  <MdDelete className="text-[18px]" />
                                </small>
                                <small>
                                  <MdDelete className="text-[18px]" />
                                </small>
                              </span>
                            </div>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Add Instructor */}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
