import React from "react";
import Layout from "../../Layout/Layout";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const CheckoutSuccess = () => {
  const pageTitle = "Thank You for Purchasing";
  const breadcrumbLinks = [
    { label: "Checkout", url: "/checkout" },
    { label: "Success", url: "/checkout/success" },
  ];
  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* container for checkout success card  */}
      <div className="min-h-[40vh] flex items-center justify-center text-gray-700 mb-10 mt-10 sm:scale-[0.9] lg:scale-95 scale-90">
        {/* card to display message */}
        <div className="w-[500px] h-[22rem] flex flex-col justify-center items-center shadow-[0_0_10px_gray] rounded-lg relative">
          <h1 className="bg-green-500 absolute top-0 w-full text-center py-4 text-2xl font-bold rounded-tl-lg rounded-tr-lg">
            Payment Successful
          </h1>

          <div className="px-4 flex flex-col items-center justify-center space-y-2">
            <div className="text-center space-y-2">
              <h2 className="text-lg font-semibold">
                Welcome to the Pro Bundle
              </h2>
              <p className="text-left">
                Now you can enjoy the taste of learning from our vast library of
                courses from the top subject matter experts of the industry
              </p>
            </div>

            {/* adding the check symbol */}
            <AiFillCheckCircle className="text-5xl text-green-500" />
          </div>

          {/* adding back to homepage button */}
          <Link
            className=" transition-all ease-in-out duration-300 absolute -bottom-[5px] w-full text-center text-xl font-bold rounded-bl-lg rounded-br-lg"
            to={"/courses"}
          >
            <div className="text-center">
              <button type="submit" className="btn bg-green-500 w-full h-full text-gray-900 hover:bg-green-600">
                <div className="flex justify-center items-center">
                  <span>
                    <small>Go to Dashboard</small>
                    <small>Go to Dashboard</small>
                  </span>
                </div>
              </button>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CheckoutSuccess;
