import axios from "axios";

// const BASE_URL = "http://localhost:8100/api/v1";
const BASE_URL = "https://api-lms.mindcoders.in/api/v1";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = BASE_URL;
axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.xsrfCookieName = "token"

export default axiosInstance;
