import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Page routes
import * as PAGE_ROUTE from "./Routes/PageRoutes"

// Component Routes
import * as COMPONENT_ROUTE from "./Routes/ComponentRoutes"

import { useDispatch } from "react-redux";
import { getUserData } from "./Redux/authSlice";
import AOS from "aos";
import "aos/dist/aos.css";
import Cookies from "js-cookie"

const App = () => {
  const loggedInStatus = localStorage.getItem("isLoggedIn");
  const token = Cookies.get("token")
  const dispatch = useDispatch();
  useEffect(() => {
    if (loggedInStatus && token) {
      dispatch(getUserData());
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<PAGE_ROUTE.HomePage />} />
        <Route path="/course" element={<PAGE_ROUTE.CourseList />} />
        <Route path="/contact" element={<PAGE_ROUTE.Contact />} />
        <Route path="/about" element={<PAGE_ROUTE.About />} />
        <Route path="/denied" element={<PAGE_ROUTE.Denied />} />
        <Route path="/forgetpassword" element={<PAGE_ROUTE.ForgetPassword />} />
        <Route path="/reset-password/:resetToken" element={<PAGE_ROUTE.ResetPassword />} />

        <Route element={<COMPONENT_ROUTE.NotRequireAuth />}>
          <Route path="/login" element={<PAGE_ROUTE.Login />} />
          <Route path="/signup" element={<PAGE_ROUTE.Signup />} />
        </Route>

        <Route element={<COMPONENT_ROUTE.RequireAuth allowedRoles={["USER", "ADMIN"]} />}>
          <Route path="/course/description" element={<PAGE_ROUTE.CourseDescription />} />
          <Route path="/checkout" element={<PAGE_ROUTE.Checkout />} />
          <Route path="/checkout/success" element={<PAGE_ROUTE.CheckoutSuccess />} />
          <Route path="/checkout/fail" element={<PAGE_ROUTE.CheckoutFail />} />
          <Route path="/changepassword" element={<PAGE_ROUTE.ChangePassword />} />
          <Route path="/user/profile" element={<PAGE_ROUTE.Profile />} />
          <Route path="/user/editprofile" element={<PAGE_ROUTE.EditProfile />} />
          <Route path="/mycourses" element={<PAGE_ROUTE.MyCourses />} />
          <Route path="/course/displaylectures/:id" element={<PAGE_ROUTE.DisplayLectures />} />
        </Route>

        <Route element={<COMPONENT_ROUTE.RequireAuth allowedRoles={["ADMIN"]} />}>
          <Route path="/admin/dashboard" element={<PAGE_ROUTE.AdminDashboard />} />
          <Route path="/course/addlecture" element={<PAGE_ROUTE.AddLecture />} />
          <Route path="/course/create" element={<PAGE_ROUTE.CreateCourse />} />
          <Route path="/add/instructor" element={<PAGE_ROUTE.AddInstructor />} />
        </Route>

        <Route path="*" element={<PAGE_ROUTE.NotFound />} />
      </Routes>
      <COMPONENT_ROUTE.ScrollToTop />
    </>
  );
};

export default App;
