import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout/Layout";
import { getAllCourses } from "../../Redux/courseSlice";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const Courses = () => {
  const dispatch = useDispatch();
  const { coursesData } = useSelector((state) => state.course);
 

  const breadcrumbLinks = [
    { label: "Home", url: "/" },
    { label: "Our Courses", url: "/course" },
  ];
  const pageTitle = "Our Courses";

  useEffect(() => {
    (async () => {
      await dispatch(getAllCourses());
    })();
  }, []);

  return (
    <Layout>
      {/* courses container Banner */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* courses container for displaying the cards */}
      <div className="min-h-[90vh] gap-10">
        {/* wrapper for courses card */}
        <div className="grid justify-items-center justify-center lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 mb-10">
          {coursesData?.map((element) => {
            return <COMPONENT_ROUTE.CourseCard key={element._id} data={element} />;
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Courses;
