import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axiosInstance from "../Helper/axiosInstance";
import Layout from "../Layout/Layout";
import { IoIosSend, IoLogoWhatsapp } from "react-icons/io";
import Bredcrumb from "../Components/Bredcrumb";
import { BiLogoGmail, BiSolidPhoneCall } from "react-icons/bi";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const Contact = () => {
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
  });

  const breadcrumbLinks = [
    { label: "Home", url: "/" },
    { label: "Contact Us", url: "/contact" },
  ];
  const pageTitle = "Contact Us";

  // function to handle the input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
  };

  // function to send form data to backend
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // check for empty fields
    if (
      !userInput.email ||
      !userInput.name ||
      !userInput.number ||
      !userInput.subject
    ) {
      toast.error("All fields are mandatory");
      return;
    }

    // email validation using regex
    if (
      !userInput.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      toast.error("Invalid email id");
      return;
    }

    try {
      const res = axiosInstance.post("/contact", { ...userInput });
      console.log(res);
      toast.promise(res, {
        loading: "Submitting your message...",
        success: "Form submitted successfully",
        error: "Failed to submit the form",
      });
      const response = await res;

      // clearing the input fields after successfull submission of form
      if (response?.data?.success) {
        setUserInput({
          name: "",
          email: "",
          number: "",
          subject: "",
        });
      }
    } catch (error) {
      toast.error("Operation failed...");
    }
  };

  return (
    <Layout>
      {/* Bredcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Contact details */}
      <section className="contact_section section_space_lg pt-0">
        <div className="container">
          <div className="section_space_lg pt-0">
            <div className="row justify-content-center">
              <div
                className="col col-lg-3 col-md-6 col-sm-12"
              >
                <div
                  className="contact_info_box"
                  style={{
                    backgroundImage:
                      'url("assets/images/shapes/dot_shape_3.png")',
                  }}
                >
                  <div
                    className="inner_wrap tilt"
                    style={{
                      willChange: "transform",
                      transform:
                        "perspective(1000px) rotateX(0deg) rotateY(0deg)",
                    }}
                  >
                    <div className="item_icon">
                      <img
                        src="assets/images/icons/icon_support.png"
                        alt="Icon Support"
                      />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Our Telephone</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <a href="tel:6281560233">
                            <BiSolidPhoneCall className="text-lg mr-2" />
                            6281560233
                          </a>
                        </li>
                        <li>
                          <a href="tel:7674040233">
                            <BiSolidPhoneCall className="text-lg mr-2" />
                            7674040233
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col col-lg-3 col-md-6 col-sm-12"
              >
                <div
                  className="contact_info_box"
                  style={{
                    backgroundImage:
                      'url("assets/images/shapes/dot_shape_3.png")',
                  }}
                >
                  <div
                    className="inner_wrap tilt"
                    style={{
                      willChange: "transform",
                      transform:
                        "perspective(1000px) rotateX(0deg) rotateY(0deg)",
                    }}
                  >
                    <div className="item_icon">
                      <img
                        src="assets/images/icons/icon_email.png"
                        alt="Icon Email"
                      />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Send us mail</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <a href="mailto:hr@mindcoders.in">
                            <BiLogoGmail className="text-lg mr-2" />
                            hr@mindcoders.in
                          </a>
                        </li>
                        <li>
                          <a href="mailto:contact@mindcoders.in">
                            <BiLogoGmail className="text-lg mr-2" />
                            contact@mindcoders.in
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col col-lg-3 col-md-6 col-sm-12"
              >
                <div
                  className="contact_info_box"
                  style={{
                    backgroundImage:
                      'url("assets/images/shapes/dot_shape_3.png")',
                  }}
                >
                  <div
                    className="inner_wrap tilt"
                    style={{
                      willChange: "transform",
                      transform:
                        "perspective(1000px) rotateX(0deg) rotateY(0deg)",
                    }}
                  >
                    <div className="item_icon">
                      <img
                        className="w-[90px]"
                        src="assets/images/icons/WhatsApp_icon.png"
                        alt="Icon Email"
                      />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Ping us</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <a href="https://api.whatsapp.com/send?phone=6281560233">
                            <IoLogoWhatsapp className="text-lg mr-2" />
                            6281560233
                          </a>
                        </li>
                        <li>
                          <a href="https://api.whatsapp.com/send?phone=7674040233">
                            <IoLogoWhatsapp className="text-lg mr-2" />
                            7674040233
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col col-lg-3 col-md-6 col-sm-12"
              >
                <div
                  className="contact_info_box"
                  style={{
                    backgroundImage:
                      'url("assets/images/shapes/dot_shape_3.png")',
                  }}
                >
                  <div
                    className="inner_wrap tilt"
                    style={{
                      willChange: "transform",
                      transform:
                        "perspective(1000px) rotateX(0deg) rotateY(0deg)",
                    }}
                  >
                    <div className="item_icon">
                      <img
                        className="w-[90px]"
                        src="assets/images/icons/location_icon.png"
                        alt="Icon Support"
                      />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title">Address</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>206, Pearl Business park,</li>
                        <li>In front of Vishnupuri IBus Stop,</li>
                        <li>Bhanwarkua, Indore, India, 452014</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Contact form */}
          <div className="row justify-content-center">
            <div className="col col-lg-9">
              <div
                className="contact_form_wrapper"
              >
                <h2 className="contact_title text-center">Have a quetions?</h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col col-md-6">
                      <div className="form_item m-0">
                        <input
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Your Full Name"
                          value={userInput.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col col-md-6">
                      <div className="form_item m-0">
                        <input
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Enter the email"
                          value={userInput.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col col-md-6">
                      <div className="form_item m-0">
                        <input
                          id="number"
                          type="number"
                          name="number"
                          placeholder="Phone Number"
                          value={userInput.number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col col-md-6">
                      <div className="form_item m-0">
                        <input
                          id="subject"
                          type="text"
                          name="subject"
                          value={userInput.subject}
                          onChange={handleInputChange}
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-center">
                        <button type="submit" className="btn btn_primary">
                          <span>
                            <small>Send Message</small>
                            <small>Send Message</small>
                          </span>
                          <IoIosSend className="ml-1 text-xl" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
