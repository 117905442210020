import React from "react";
import { ReactTyped } from "react-typed";

const Typed = () => {
  return (
    <ReactTyped
      startWhenVisible
      strings={["Affordable", "Easy", "Practical"]}
      typeSpeed={100}
      backSpeed={100}
      loop
    />
  );
};

export default Typed;
