import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../Redux/authSlice";
import { AiOutlineLogin } from "react-icons/ai";
import { FaUserCircle, FaUserPlus } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { baseUrl } from "../Helper/baseUrl";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // for checking user logged in or not
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  // for dispaying the options, according to user role
  const role = useSelector((state) => state?.auth?.role);

  // function to handle logout
  const handleLogout = async (event) => {
    event.preventDefault();
    // calling logout action
    const res = await dispatch(logout());

    // redirect to home page if true
    if (res?.payload?.success) navigate("/");
  };

  return (
    <header className="site_header header_style_1 sticky">
      <div className="header_bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-2 col-5">
              <div className="site_logo">
                <Link className="site_link" to={"/"}>
                  <img
                    className="h-[60px]"
                    src={`${baseUrl()}/assets/images/logo/mindcoderlogo.webp`}
                    alt="Education, Online Course, LMS Creative"
                  />
                </Link>
              </div>
            </div>
            <div className="col col-lg-7 col-2">
              <nav className="main_menu navbar navbar-expand-lg">
                <div
                  className="main_menu_inner collapse navbar-collapse justify-content-center"
                  id="main_menu_dropdown"
                >
                  <ul className="main_menu_list unordered_list_center">
                    <li className={location.pathname === "/" ? "active" : ""}>
                      <Link
                        className="hover:text-[#e58471] active:text-[#e58471]"
                        to={"/"}
                      >
                        Home
                      </Link>
                    </li>

                    <li
                      className={location.pathname === "/about" ? "active" : ""}
                    >
                      <Link
                        className="hover:text-[#e58471] active:text-[#e58471]"
                        to={"/about"}
                      >
                        About Us
                      </Link>
                    </li>

                    {/* displaying dashboard, if admin is logged in */}
                    {isLoggedIn && role === "ADMIN" && (
                      <li
                        className={
                          location.pathname === "/admin/dashboard"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          className="hover:text-[#e58471] active:text-[#e58471]"
                          to={"/admin/dashboard"}
                        >
                          Admin Dashboard
                        </Link>
                      </li>
                    )}

                    <li
                      className={
                        location.pathname === "/course" ? "active" : ""
                      }
                    >
                      <Link
                        className="hover:text-[#e58471] active:text-[#e58471]"
                        to={"/course"}
                      >
                        All Courses
                      </Link>
                    </li>

                    {/* displaying myCourses, if user is logged in */}
                    {isLoggedIn && role === "USER" && (
                      <li
                        className={
                          location.pathname === "/mycourses" ? "active" : ""
                        }
                      >
                        <Link
                          className="hover:text-[#e58471] active:text-[#e58471]"
                          to={"/mycourses"}
                        >
                          My Courses
                        </Link>
                      </li>
                    )}

                    <li
                      className={
                        location.pathname === "/contact" ? "active" : ""
                      }
                    >
                      <Link
                        className="hover:text-[#e58471] active:text-[#e58471]"
                        to={"/contact"}
                      >
                        Contact Us
                      </Link>
                    </li>

                    {/* displaying myCourses, if user is logged in */}
                    {isLoggedIn && role === "USER" && (
                      <li
                        className={
                          location.pathname === "/mycourses" ? "active" : ""
                        }
                      >
                        <Link
                          className="hover:text-[#e58471] active:text-[#e58471]"
                          to={"https://www.mindcoders.in/jobs.html"}
                          target="_blank"
                        >
                          Jobs
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col col-lg-3 col-5">
              <ul className="unordered_list_end">
                {/* Login Signup buttons */}

                {isLoggedIn ? (
                  <li>
                    <div className="w-full flex gap-3 items-center justify-center">
                      <Link className="btn btn_primary" to={"/user/profile"}>
                        <button className="flex gap-2 items-center text-white">
                          <FaUserCircle />
                          <span>
                            <small>Profile</small>
                            <small>Profile</small>
                          </span>
                        </button>
                      </Link>
                      <Link className="btn btn_primary" onClick={handleLogout}>
                        <button className="flex gap-2 items-center text-white">
                          <LuLogOut />
                          <span>
                            <small>Logout</small>
                            <small>Logout</small>
                          </span>
                        </button>
                      </Link>
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className="w-full flex gap-3 items-center justify-center">
                      <Link className="btn btn_primary" to={"/login"}>
                        <button className="flex gap-2 items-center text-white">
                          <AiOutlineLogin />
                          <span>
                            <small>Login</small>
                            <small>Login</small>
                          </span>
                        </button>
                      </Link>
                      <Link className="btn btn_primary" to={"/signup"}>
                        <button className="flex gap-2 items-center text-white">
                          <FaUserPlus />
                          <span>
                            <small>Signup</small>
                            <small>Signup</small>
                          </span>
                        </button>
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
