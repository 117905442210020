import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { getUserData } from "../../Redux/authSlice";
import { cancelCourseBundle } from "../../Redux/razorpaySlice";
import { RiLockPasswordFill } from "react-icons/ri";
import { LiaUserEditSolid } from "react-icons/lia";
import { MdEmail } from "react-icons/md";
import axiosInstance from "../../Helper/axiosInstance";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const Profile = () => {
  const dispatch = useDispatch();

  const breadcrumbLinks = [{ label: "" }];
  const pageTitle = "My Profile";

  const userData = useSelector((state) => state?.auth?.data);

  // function to handle the cancel subscription of course
  const handleCourseCancelSubscription = async () => {
    await dispatch(cancelCourseBundle());
    await dispatch(getUserData());
  };

  useEffect(() => {
    // getting user details
     axiosInstance.get("/user/subscription",{UserId:"664f06fbe8143af498ed412a"}).then(value=>console.log(value.data.data)).catch(err=>console.log(err.message));
    dispatch(getUserData());
  }, []);
  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Profile component */}
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30 draggable">
        {/* card body */}
        <div className="px-9 pt-9 flex-auto min-h-[70px] pb-0 bg-transparent">
          <div className="flex flex-wrap mb-6 xl:flex-nowrap justify-end">
            <div className="mb-5 mr-5">
              <div className="relative inline-block shrink-0 rounded-2xl">
                <img
                  className="rounded-full border border-black inline-block shrink-0 w-[80px] h-[80px] lg:w-[160px] lg:h-[160px]"
                  src={userData?.avatar?.secure_url}
                  alt="user profile img"
                />
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-around w-[70vw]">
              <div className="flex flex-col">
                <div className="flex items-center mb-2">
                  <h3 className="text-slate-500 transition-colors duration-200 ease-in-out font-semibold text-[1.5rem] mr-1">
                    {userData.fullName}
                  </h3>
                  <div className="group/tooltip relative">
                    <span className="w-[15px] h-[15px] absolute left-1 bg-success rounded-full bottom-0 end-0 -mb-1 -mr-2  border border-white" />
                    <span className="text-xs absolute z-10 transition-opacity duration-300 ease-in-out px-3 py-2 whitespace-nowrap text-center transform bg-white rounded-2xl shadow-sm bottom-0 -mb-2 start-full ml-4 font-medium text-secondary-inverse group-hover/tooltip:opacity-100 opacity-0 block">
                      {" "}
                      Status: Active{" "}
                    </span>
                  </div>
                </div>
                <div className="flex flex-wrap pr-2 mb-2 font-medium">
                  <div className="flex items-center mb-2 mr-5 text-secondary-dark">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    {userData?.role}
                  </div>
                  <div className="flex items-center mb-2 mr-5 text-secondary-dark">
                    <span className="mr-1">
                      <MdEmail className="text-xl" />
                    </span>
                    {userData?.email}
                  </div>
                </div>
                <div className="flex">
                  <p>Subscription :</p>
                  <p>
                    {userData?.subscription?.status === "active"
                      ? "Active"
                      : "Inactive"}
                  </p>
                </div>
              </div>
              {/* Buttons */}
              <div className="flex flex-wrap my-auto w-[400px] ">
                <Link
                  to={
                    userData?.email === "test@gmail.com"
                      ? "/denied"
                      : "/changepassword"
                  }
                  className="w-1/2 transition-all ease-in-out duration-300 rounded-sm py-2 font-semibold cursor-pointer text-center"
                >
                  <button type="submit" className="btn btn_primary">
                    <div className="flex justify-center items-center">
                      <span>
                        <small>Change Password</small>
                        <small>Change Password</small>
                      </span>
                      <RiLockPasswordFill className="ml-1 text-xl mb-1" />
                    </div>
                  </button>
                </Link>
                <Link
                  to={
                    userData?.email === "test@gmail.com"
                      ? "/denied"
                      : "/user/editprofile"
                  }
                  className="w-1/2 transition-all ease-in-out duration-300 rounded-sm py-2 font-semibold cursor-pointer text-center"
                >
                  {/* <button>Edit Profile</button> */}
                  <button
                    type="submit"
                    className="btn btn_primary bg-[#E97862]"
                  >
                    <div className="flex justify-center items-center">
                      <span>
                        <small>Edit Profile</small>
                        <small>Edit Profile</small>
                      </span>
                      <LiaUserEditSolid className="ml-1 text-xl mb-1" />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
