import React from "react";
import Layout from "../Layout/Layout";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { baseUrl } from "../Helper/baseUrl";

// Component Routes
import * as COMPONENT_ROUTE from "../Routes/ComponentRoutes"

const About = () => {
  const breadcrumbLinks = [
    { label: "Home", url: "/" },
    { label: "About us", url: "/about" },
  ];
  const pageTitle = "About us";

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb breadcrumbLinks={breadcrumbLinks} pageTitle={pageTitle} />

      {/* About section */}
      <section className="about_section section_space_lg pt-0">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            
            <div className="col col-lg-5 col-md-4 col-sm-12">
              <div
                className="about_image lg:w-[85%]"
              >
                <div className="image_wrap relative lg:left-[80px] sm:left[30] lg:w-[100%] top-[60px]">
                  <img
                    src={`${baseUrl()}/assets/images/about/about-img.png`}
                    alt="Person Img"
                  />
                </div>
              </div>
            </div>
            <div className="col col-lg-6 mt-11 col-md-8 col-sm-12">
              <div className="about_content">
                <h2 className="about_title">
                  We have development experts in all technologies
                </h2>
                <p>
                  Welcome to Mind-Coders, your premier destination for
                  cutting-edge IT training solutions. With a commitment to
                  excellence, we offer a diverse range of courses catering to
                  various skill levels and industry demands.
                </p>
                <Link to="/contact">
                  <button className="btn btn_primary" >
                  <span>
                    <small>Contact Us</small>
                    <small>Contact Us</small>
                  </span>
                  <FaLongArrowAltRight className="ml-2 text-xl" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Parterns section */}
      <section className="partner_section section_space_lg bg_info">
      <COMPONENT_ROUTE.ClientScroller/>
      </section>

      {/* Mission vission section */}
      <section className="goal_section section_space_lg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-4">
              <div className="section_heading mb-lg-0">
                <h2 className="heading_text mb-0">
                  We have helped millions of people worldwide unlock technical
                  skills
                </h2>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6">
              <div className="about_item">
                <div className="item_image">
                  <img
                    src="assets/images/about/about_img_2.jpg"
                    alt="Our History"
                  />
                </div>
                <div className="item_content">
                  <h3 className="item_title">Our History</h3>
                  <p className="mb-0">
                    our goal was to give anyone in the world the ability to
                    learn the skills they’d need to succeed in the 21st century
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6">
              <div className="about_item">
                <div className="item_image">
                  <img
                    src={`${baseUrl()}/assets/images/about/mission.jpg`}
                    alt="Our Mission"
                  />
                </div>
                <div className="item_content">
                  <h3 className="item_title">Our Mission</h3>
                  <p className="mb-0">
                    our goal was to give anyone in the world the ability to
                    learn the skills they’d need to succeed in the 21st century
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Counterns */}
      <div>
      <COMPONENT_ROUTE.CountupCard />
      </div>

      {/* Students Reviews */}

      {/* <SwiperSlider /> */}
    </Layout>
  );
};

export default About;
