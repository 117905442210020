import React from "react";
import Layout from "../../Layout/Layout";
import { RxCrossCircled } from "react-icons/rx";
import { Link } from "react-router-dom";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const CheckoutFail = () => {
  const pageTitle = "Please Try Again";
  const breadcrumbLinks = [
    { label: "Checkout", url: "/checkout" },
    { label: "Failed", url: "/checkout/failed" },
  ];
  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* container for checkout fail card  */}
      <div className="min-h-[40vh] flex items-center justify-center text-gray-700 mb-10 mt-10 sm:scale-[0.9] lg:scale-95 scale-90">
        {/* card to display message */}
        <div className="w-[500px] h-[22rem] flex flex-col justify-center items-center shadow-[0_0_10px_gray] rounded-lg relative">
          <h1 className="bg-red-500 absolute top-0 w-full text-center py-4 text-2xl font-bold rounded-tl-lg rounded-tr-lg">
            Payment failed
          </h1>

          <div className="px-4 flex flex-col items-center justify-center space-y-2">
            <p className="text-center">
              <h2 className="text-lg font-semibold">
                Oops! Your Payment Failed
              </h2>
              Please try it again as it can be a temporary issue.
            </p>

            {/* adding the check symbol */}
            <RxCrossCircled className="text-5xl text-red-500" />

            <p>Failed</p>
          </div>

          {/* going back to payment page again */}
          <Link
            className=" transition-all ease-in-out duration-300 absolute -bottom-[10px] w-full text-center py-2 text-xl font-bold rounded-bl-lg rounded-br-lg"
            to={"/checkout"}
          >
            {/* <button>Revisit Payment</button> */}
            <button type="submit" className="btn bg-red-500 hover:bg-red-600 w-full h-full text-gray-900 ">
                <div className="flex justify-center items-center">
                  <span>
                    <small>Revisit Payment</small>
                    <small>Revisit Payment</small>
                  </span>
                </div>
              </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CheckoutFail;
