import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { changePassword } from "../../Redux/authSlice";
import { MdPassword } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadcrumbLinks = [
    // { label: "Home", url: "/" },
    { label: "Enter your old and new password" },
  ];
  const pageTitle = "Change Password";

  const [userPassword, setUserPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });

  // function to handle input box change
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setUserPassword({
      ...userPassword,
      [name]: value,
    });
  };

  // function to handle form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // checking the fields are empty or not
    if (!userPassword.oldPassword || !userPassword.newPassword) {
      toast.error("All fields are mandatory");
      return;
    }

    // validating the password using regex
    if (
      !userPassword.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)
    ) {
      toast.error(
        "Minimum password length should be 6 with Uppercase, Lowercase, Number and Symbol"
      );
      return;
    }

    // calling the api from auth slice
    const res = await dispatch(changePassword(userPassword));

    // clearing the input fields
    setUserPassword({
      oldPassword: "",
      newPassword: "",
    });

    // redirecting to profile page if password changed
    if (res.payload.success) navigate("/user/profile");
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* forget password container */}

      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="email">
                    Old Password
                    </label>
                    <input
                      required
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      placeholder="Enter your old password"
                      value={userPassword.oldPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="email">
                    New Password
                    </label>
                    <input
                      required
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      placeholder="Enter your new password"
                      value={userPassword.newPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Change Password</small>
                          <small>Change Password</small>
                        </span>
                        <MdPassword className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                  <p className="mt-2 text-center">
                  
                    <Link to={"/user/profile"}>
                      <span className="text-blue-600 flex items-center"><IoMdArrowRoundBack className="text-2xl" />Back to Profile</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default ChangePassword;
