import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { addCourseLecture } from "../../Redux/lectureSlice";
import { FaPlus } from "react-icons/fa";
import { IoArrowBackSharp } from "react-icons/io5";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const AddLectures = () => {
  const courseDetails = useLocation().state;

  // for breadcrumb
  const breadcrumbLinks = [
    { label: "Display Lectures", url: "/course/displaylectures" },
    { label: "Add Lectures", url: "/course/addlecture" }
  ];
  const pageTitle = "Add New Lectures";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    id: courseDetails?._id,
    lecture: undefined,
    title: "",
    description: "",
    videoSrc: "",
  });

  // function to handle the input box change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value });
  };

  // function to get video and its link from the input
  const getVideo = (event) => {
    const video = event.target.files[0];
    const source = window.URL.createObjectURL(video);
    setUserInput({ ...userInput, videoSrc: source, lecture: video });
  };

  // function to handle the form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // checking for the empty fields
    if (!userInput.lecture || !userInput.title || !userInput.description) {
      toast.error("All fields are mandatory");
      return;
    }

    const res = await dispatch(addCourseLecture(userInput));
    if (res?.payload?.success) {
      setUserInput({
        id: courseDetails?._id,
        lecture: undefined,
        title: "",
        description: "",
        videoSrc: "",
      });
    }
      navigate("/admin/dashboard")
  };

  // redirecting the user if no user details
  useEffect(() => {
    if (!courseDetails) {
      navigate(-1);
    }
  }, []);
  
  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Form section */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  {/* for course basic details */}
                  <div className="space-y-6">
                    {/* adding the title section */}
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                        Lecture Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={userInput.title}
                        onChange={handleInputChange}
                        placeholder="Enter the title for lecture"
                        className="bg-transparent px-3 py-1 border"
                      />
                    </div>
                    <div className="form_item">
                      <label className="text-lg font-semibold" htmlFor="title">
                      Lecture Description
                      </label>
                      <textarea
                        required
                        name="description"
                        value={userInput.description}
                        onChange={handleInputChange}
                        placeholder="Enter the description for lecture"
                      />
                    </div>
                    {userInput.videoSrc ? (
                      <video
                        src={userInput.videoSrc}
                        muted
                        controls
                        controlsList="nodownload nofullscreen"
                        disablePictureInPicture
                        className="object-fill rounded-tl-lg rounded-tr-lg w-full"
                      ></video>
                    ) : (
                      <div className="h-48 border flex items-center justify-center cursor-pointer">
                        <label
                          htmlFor="lecture"
                          className="font-semibold text-xl cursor-pointer"
                        >
                          Choose your video
                        </label>
                        <input
                          type="file"
                          name="lecture"
                          id="lecture"
                          onChange={getVideo}
                          accept="video/mp4,video/x-m4v,video/*"
                          className="hidden"
                        />
                      </div>
                    )}
                  </div>

                  <div className="text-center mt-3">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Add Lecture</small>
                          <small>Add Lecture</small>
                        </span>
                        <FaPlus className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                  <div className="remenber_forget mt-3 text-center justify-between">
                    <div>
                      <div className="forget_password text-center">
                        <Link to={"/course/displaylectures"}>
                          <span className="text-blue-600 flex gap-1 items-center">
                            <IoArrowBackSharp className="text-xl" />
                            Back to Display Lectures
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddLectures;
