import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { resetPassword } from "../../Redux/authSlice";
import { IoIosSend } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadcrumbLinks = [
    // { label: "Home", url: "/" },
    { label: "Please enter New Password" },
  ];
  const pageTitle = "Reset Password";

  const [data, setData] = useState({
    password: "",
    cnfPassword: "",
    resetToken: useParams().resetToken,
  });

  // function to handle user input
  const handleUserInput = (event) => {
    const { name, value } = event.target;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  // function to handle form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // check the empty field
    if (!data.password || !data.cnfPassword || !data.resetToken) {
      toast.error("All fields are mandatory");
      return;
    }

    // password validation using regex
    if (!data.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/)) {
      toast.error(
        "Minimum password length should be 8 with Uppercase, Lowercase, Number and Symbol"
      );
      return;
    }

    // matching the password
    if (data.password !== data.cnfPassword) {
      toast.error("Both password should be same");
      return;
    }

    // calling the api to reset password
    const res = await dispatch(resetPassword(data));

    // redirecting to the login page
    if (res.payload.success) {
      navigate("/login");
    }
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
      {/* forget password container */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="email">
                    New Password
                    </label>
                    <input
                      required
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your new password"
                      value={data.password}
              onChange={handleUserInput}
                    />
                  </div>
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="password">
                    Confirm New Password
                    </label>
                    <input
                       required
                       type="password"
                       name="cnfPassword"
                       id="cnfPassword"
                       placeholder="Confirm your new password"
                       value={data.cnfPassword}
                       onChange={handleUserInput}
                    />
                  </div>
                  <div className="remenber_forget mb-3 text-center">
                    <div>
                      <p className="mb-0 text-center">
                        Don't have an account?{" "}
                        <Link to={"/signup"}>
                          <span className="text-blue-600">Register Here</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Reset Password</small>
                          <small>Reset Password</small>
                        </span>
                        <IoIosSend className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                  <div>
                    <p className="mb-0 text-center">
                        Already have an account?{" "}
                        <Link to={"/login"}>
                          <span className="text-blue-600">Login</span>
                        </Link>
                      </p>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
