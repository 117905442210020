import React from "react";
import CountUp from "react-countup";

const CountupCard = () => {
  return (
    <section className="counter_section decoration_wrap" data-aos="fade-up" data-aos-duration="1500">
  <div className="container">
    <div className="row ml-2 align-items-center justify-content-lg-between">
      <div className="col col-lg-4 col-12">
        <h2 className="area_title text-white">
          <span className="d-lg-block">Join in on</span> something big
        </h2>
      </div>
      <div className="col col-lg-2 col-md-4 col-sm-4">
        <div className="counter_item text-center">
          <h3 className="counter_value text-white">
            <span className="value_text"><CountUp end={100} delay={5} /></span>M
          </h3>
          <h4 className="counter_title text-white mb-0">Learners</h4>
        </div>
      </div>
      <div className="col col-lg-2 col-md-4 col-sm-4">
        <div className="counter_item text-center">
          <h3 className="counter_value text-white">
            <span className="value_text"><CountUp end={190} delay={5} /></span>+
          </h3>
          <h4 className="counter_title text-white mb-0">Countries</h4>
        </div>
      </div>
      <div className="col col-lg-2 col-md-4 col-sm-4">
        <div className="counter_item text-center">
          <h3 className="counter_value text-white">
            <span className="value_text"><CountUp end={3} delay={5} /></span>B
          </h3>
          <h4 className="counter_title text-white mb-0">Code Submits</h4>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default CountupCard;
