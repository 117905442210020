import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { forgetPassword } from "../../Redux/authSlice";
import { IoIosSend } from "react-icons/io";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const breadcrumbLinks = [
    // { label: "Home", url: "/" },
    { label: "Enter your registered email", url: "/forgetpassword" },
  ];
  const pageTitle = "Forget Password";

  // function to handle submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // checking for the empty field
    if (!email) {
      toast.error("All fields are mandatory");
      return;
    }

    // email validation using regex
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      toast.error("Invalid email id");
      return;
    }

    // calling the api from auth slice
    const res = await dispatch(forgetPassword(email));

    // clearing the input fields
    setEmail("");
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* forget password container */}
      {/* Login Form */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleFormSubmit}>
                <div className="register_form signup_login_form">
                  <div className="form_item">
                    <label className="text-lg font-semibold" htmlFor="email">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your registered email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Get Verification Link</small>
                          <small>Get Verification Link</small>
                        </span>
                        <IoIosSend className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                  <p className="mb-0 text-center">
                    Already have an account ?{" "}
                    <Link to={"/login"}>
                      <span className="text-blue-600">Login</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgetPassword;
