import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getRazorPayId,
  purchaseCourseBundle,
  verifyUserPayment,
} from "../../Redux/razorpaySlice";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Helper/axiosInstance";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const Checkout = () => {
  const pageTitle = "Subscription Bundle";
  const breadcrumbLinks = [
    { label: "Course Details", url: "/course/description" },
    { label: "Subscribe", url: "/checkout" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const razorPayKey = useSelector((state) => state?.razorpay?.key);
  const subscription_id = useSelector(
    (state) => state?.razorpay?.subscription_id
  );
  const userData = useSelector((state) => state?.auth?.data);
  const { isPaymentVerified } = useSelector((state) => state?.razorpay);
  const { state } = useLocation();
  console.log("state",state)

  // for storing the payment details after successfull transaction
  const paymentDetails = {
    razorpay_payment_id: "",
    razorpay_subscription_id: "",
    razorpay_signature: "",
    state
  };

  const handleSubscription = async (event) => {
    event.preventDefault();
  
  
    console.table({razorPayKey:razorPayKey,subscription_id:subscription_id})
    // checking for empty payment credential
    if (!razorPayKey || !subscription_id) {
      toast.error("server error! Try Again To Subscribe The Course")
      return;
    }

    const options = {
      key: razorPayKey,
      subscription_id: subscription_id,
      name: "Mindcoders Pvt. Ltd.",
      description: "Mind Coders is your One Stop for all IT Training Needs! We provide the best training with industry expert trainers. Join Professional Courses For Guaranteed Job and Better Career Growth.",
      handler: async function (response) {
        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
        paymentDetails.razorpay_subscription_id =
          response.razorpay_subscription_id;
        paymentDetails.razorpay_signature = response.razorpay_signature;

        // displaying the success message
        // toast.success("Payment Successfull");

        try {
          // Verifying the payment
          const res = await dispatch(verifyUserPayment(paymentDetails));
    
          // Redirecting the user based on the verification status
          if (res?.payload?.success) {
            // If payment is verified successfully
            navigate("/checkout/success");
          } else {
            // If payment verification fails
            navigate("/checkout/fail");
          }
        } catch (error) {
          // Handle any errors occurred during payment verification
          console.error("Error verifying payment:", error);
          navigate("/checkout/fail");
        }
      },
      prefill: {
        name: userData.fullName,
        email: userData.email,
      },
      theme: {
        color: "#F37254",
      },
    };
    console.log("Razorpay options: ", options);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    (async () => {
      await dispatch(getRazorPayId());
      await dispatch(purchaseCourseBundle({courseId:state?.courseId,planId:state?.planId}))
    })();
  }, []);

  return (
    <Layout>
      {/* Breadcrumb */}
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* Checkout form */}
      <div className="register_section section_space_lg pt-0 mt-[100px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <form onSubmit={handleSubscription}>
                <div className="register_form signup_login_form">
                  <h1 className="text-xl font-bold text-center mb-2">Order Details</h1>
                  <div className="form_item">
                    <p className="text-[17px]">
                      This purchase will allow you to access all the available
                      courses of our platform for{" "}
                      <span className="text-[#0FCFFF] font-bold">
                        1 Year Duration
                      </span>
                      . <br />
                      All the existing and new launched courses will be
                      available to you in this subscription bundle
                    </p>
                    <>
                      <div className="flex justify-between font-nunito mt-6 large:!mt-4">
                        <p className="text-md medium:text-sm leading-[18px] medium:leading-[22px] font-normal text-gray-650">
                          Subtotal
                        </p>
                        <p className="text-sm medium:text-sm leading-[18px] medium:leading-[22px] font-normal font-inter text-gray-650">
                        ₹ {state?.courseFee}
                        </p>
                      </div>
                      <div className="flex justify-between mt-2">
                        <p className="text-md medium:text-sm leading-[18px] medium:leading-[22px] font-normal text-gray-650">
                          Discount
                        </p>
                        <p className="text-sm medium:text-sm leading-[18px] medium:leading-[22px] font-normal text-green-500 font-inter">
                          - ₹ 0.00
                        </p>
                      </div>
                      <hr className="mt-2 border-b-0 border-gray-150" />
                      <div className="flex justify-between ">
                        <p className="text-md medium:text-base leading-[21px] medium:leading-6 font-bold text-gray-900">
                          Total
                        </p>
                        <p className="text-sm medium:text-base leading-[21px] medium:leading-6 font-semibold font-inter text-gray-900">
                          ₹ {state?.courseFee}
                        </p>
                      </div>
                    </>

                    <div className="text-gray-600">
                      <p>100% refund at cancellation</p>
                      <p>* Terms & Condition Applied</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary">
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Proceed To Checkout</small>
                          <small>Proceed To Checkout</small>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
