import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Helper/axiosInstance";
import toast from "react-hot-toast";

const initialState = {
  instructor: [],
};

// function to get all instructor
export const getAllInstructor = createAsyncThunk(
  "/instructor/get",
  async () => {
    try {
      const res = axiosInstance.get("/instructor/getall");

      // toast.promise(res, {
      //   loading: "Loading courses data...",
      //   success: "Courses loaded successfully",
      //   error: "Failed to get courses",
      // });

      const response = await res;

      return response.data.instructor;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// function to create a new course
export const addNewInstructor = createAsyncThunk(
  "/get/instructors",
  async (data) => {
    try {
      // creating the form data from user data
      let formData = new FormData();
      formData.append("name", data?.name);
      formData.append("email", data?.email);
      formData.append("password", data?.password);
      formData.append("experties", data?.experties);
      formData.append("experience", data?.experience);

      const res = axiosInstance.post("/instructor/register", data);

      toast.promise(res, {
        loading: "Adding the instructor...",
        success: "Instructor added successfully",
        error: "Failed to add instructor",
      });

      const response = await res;
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// function to delete the instructor
export const deleteInstructor = createAsyncThunk(
  "/instructor/delete",
  async (id) => {
    try {
      const res = axiosInstance.delete(`/instructor/delete/${id}`);

      toast.promise(res, {
        loading: "Deleting the instructor...",
        success: "Instructor deleted successfully",
        error: "Failed to delete instructor",
      });

      const response = await res;

      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// function to update the course details
export const updateInstructor = createAsyncThunk("/course/update", async (data) => {
  try {
    // creating the form data from user data
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("password", data?.password);
    formData.append("experties", data?.experties);
    formData.append("experience", data?.experience);

    const res = axiosInstance.put(`/instructor/update/${data.id}`, {
      name: data.name,
      email: data.email,
      password: data.password,
      experties: data.experties,
      experience: data.experience,
    });

    toast.promise(res, {
      loading: "Updating the instructor...",
      success: "Instructor updated successfully",
      error: "Failed to update instructor",
    });

    const response = await res;
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
  }
});

const instructorSlice = createSlice({
  name: "instructor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllInstructor.fulfilled, (state, action) => {
      if (action.payload) {
        state.instructorData = [...action.payload];
      }
    });
  },
});

export const {} = instructorSlice.actions;
export default instructorSlice.reducer;
