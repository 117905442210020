import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { confirmAlert } from "react-confirm-alert";
import {
  deleteCourseLecture,
  getCourseLecture,
} from "../../Redux/lectureSlice";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const DisplayLectures = () => {
  // console.log("data", data);
  const {id} = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for getting the data from location of previous component
  const courseDetails = useLocation().state;
  // console.log(courseDetails);
  const { lectures } = useSelector((state) => state?.lecture);
  console.log("lectures", lectures);
  const { role } = useSelector((state) => state?.auth);

  // to play the video accordingly
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // function to handle lecture delete
  const handleLectureDelete = async (courseId, lectureId) => {
    const data = { courseId, lectureId };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to remove the selected lecture?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const res =  await dispatch(deleteCourseLecture(data));
            // fetching the new updated data for the course
            if (res?.payload?.success) {
              await dispatch(getCourseLecture(courseDetails?._id));
            }
          },
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing if user clicks 'No'
        },
      ],
    });
  
  
    console.log("data", data);
   
  };

  // fetching the course lecture data
  useEffect(() => {
    (async () => {
      await dispatch(getCourseLecture(id));
      // console.log("2",getCourseLecture.payload);
    })();
  }, []);

  return (
    <Layout>
      <section className="pt-[120px]">
        <h1 className="text-center capitalize text-2xl font-semibold text-stone-600">
          {lectures[currentVideoIndex]?.title}
        </h1>

        <div className="flex flex-col gap-10 items-center justify-center min-h-[90vh] py-10 text-stone-600">
          {/* displaying the course name */}

          <div className="flex justify-center flex-wrap sm:flex-col md:flex-col lg:flex-row gap-10 w-full">
            {/* left section for playing the video and displaying course details to admin */}
            <div className="space-y-5  sm:w-[95%] md:w-[95%] lg:w-[60%] m-auto  rounded-lg">
              <video
                className="object-fill rounded-tl-lg rounded-tr-lg w-full"
                src={
                  lectures && lectures[currentVideoIndex]?.lecture?.secure_url
                }
                controls
                disablePictureInPicture
                muted
                controlsList="nodownload"
              >
                <source   src={
                  lectures && lectures[currentVideoIndex]?.lecture?.secure_url
                } type="video/mp4"/>
              </video>
              <div>
                <h1 className="bg-gray-50 hover:bg-gray-100 w-full p-4 cursor-pointer rounded-md border border-gray-300 transition-colors duration-300">
                  <span className="text-slate-600 text-2xl font-semibold">
                    Title :{" "}
                  </span>
                  {lectures && lectures[currentVideoIndex]?.title}
                </h1>
                <p className="mt-3 bg-gray-50 hover:bg-gray-100 w-full p-4 cursor-pointer rounded-md border border-gray-300 transition-colors duration-300">
                  {" "}
                  <span className="text-slate-600 text-2xl font-semibold line-clamp-4">
                    Description :
                  </span>
                  {lectures && lectures[currentVideoIndex]?.description}
                </p>
              </div>
            </div>

            {/* right section for displaying all the lectures of the course */}
            <ul className="lg:mr-[50px] sm:w-[95%] md:w-[95%] lg:w-[30%] p-2 rounded-lg border border-red-900 space-y-5">
              <li className="font-semibold text-xl flex items-center justify-between">
                <p className="lg:ml-5">Lectures List</p>
                {role === "ADMIN" && (
                  <div className="text-center">
                    <button
                      onClick={() =>
                        navigate("/course/addlecture", {
                          state: { ...courseDetails },
                        })
                      }
                      className="btn btn_primary"
                    >
                      <div className="flex justify-center items-center">
                        <span>
                          <small>Add Lecture</small>
                          <small>Add Lecture</small>
                        </span>
                        <FaPlus className="ml-1 text-xl mb-1" />
                      </div>
                    </button>
                  </div>
                )}
              </li>
              {lectures &&
                lectures.map((element, index) => {
                  return (
                    <li
                      className=" flex items-center justify-between  transition hover:shadow-[0_0_10px_gray] hover:bg-gray-100 p-2 cursor-pointer rounded-md border border-gray-300"
                      key={element._id}
                    >
                      <div className="flex items-center gap-2">
                        <span className="text-[#1a505e] font-semibold">
                          Lecture {index + 1} :
                        </span>
                        <p
                          className=""
                          onClick={() => setCurrentVideoIndex(index)}
                        >
                          {element?.title}
                        </p>
                      </div>
                      {role === "ADMIN" && (
                        <button
                          onClick={() =>
                            handleLectureDelete(
                              courseDetails?._id,
                              element?._id
                            )
                          }
                          className="btn btn_danger"
                        >
                          <div className="flex justify-center items-center">
                            <span>
                              <small>
                                <MdDelete className="text-[18px]" />
                              </small>
                              <small>
                                <MdDelete className="text-[18px]" />
                              </small>
                            </span>
                          </div>
                        </button>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DisplayLectures;
