import React from 'react'
import { Link } from 'react-router-dom'

const Bredcrumb = ({ pageTitle, breadcrumbLinks }) => {
  return (
    <>
      <section className="page_banner">
        <div className="container">
          <h1 className="page_heading">{pageTitle}</h1>
          <ul className="breadcrumb_nav unordered_list_center">
          {breadcrumbLinks?.map((link, index) => (
            <li key={index}>
              <Link to={link?.url}>{link.label}</Link>
            </li>
          ))}
        </ul>
        </div>
      </section>
    </>
  )
}

export default Bredcrumb
