import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout/Layout";
import CourseCard from "../../Components/CourseCard";
import { getUserSubscribedCourses } from "../../Redux/authSlice";

// Component Routes
import * as COMPONENT_ROUTE from "../../Routes/ComponentRoutes"

const MyCourses = () => {
  const dispatch = useDispatch();
  const { coursesData } = useSelector((state) => state?.course);
  const { userCourse } = useSelector((state) => state.auth);
  console.log(userCourse);
  const { data } = useSelector((state) => state.auth);
  const breadcrumbLinks = [
    { label: "Home", url: "/" },
    { label: "My Courses", url: "/mycourses" },
  ];
  const pageTitle = "My Courses";

  useEffect(() => {
    dispatch(getUserSubscribedCourses());
  }, []);

  return (
    <Layout>
      <COMPONENT_ROUTE.Bredcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />

      {/* {userCourse?.length ? ( */}
        <div className="px-3 gap-10">
          {userCourse?.some((course) => course.status === "active") ? (
            <div className="grid justify-items-center justify-center lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 mb-10">
              {userCourse
                .filter((course) => course.status === "active")
                .map((course) => (
                  <CourseCard
                    key={course._id}
                    data={course.courseId}
                    courseStatus={course}
                  />
                ))}
            </div>
          ) : (
            <div className="h-[300px] flex justify-center items-center">
              <h1 className="text-2xl p-5 text-center">
                No active courses available
              </h1>
            </div>
          )}
        </div>
      {/* ) : (
        <div className="h-[300px] flex justify-center items-center">
          <h1 className="text-2xl p-5 text-center">
            No courses in your bucket
          </h1>
        </div>
      )} */}
    </Layout>
  );
};

export default MyCourses;
